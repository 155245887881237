import React, { ReactNode } from 'react';
import { Text } from '@sede-x/shell-ds-react-framework';
import { FormikValues, useFormikContext } from 'formik';
import { ServicesDto } from '../../OBUTollServiceModification/components/types';

const BoldText = ({ children }: { children: ReactNode }) => (
  <span className="font-bold">{children}</span>
);

const Summary: React.FC = () => {
  const { values } = useFormikContext<FormikValues>();

  return (
    <div className="flex flex-col grow overflow-y-auto p-5 bg-shellExtraPaleGrey2">
      <div className="flex flex-col">
        <Text>
          <BoldText> Obus will be replaced:</BoldText>
        </Text>
        <ul
          className="list-disc 
        list-outside p-2 m-2"
        >
          {values?.services?.map((service: ServicesDto) => (
            <li key={service.obuId}>{service.telepassObuNumber}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Summary;
