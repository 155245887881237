import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { customerEndPoints } from 'api/apiEndpoints';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import OverviewColumns from 'components/Table/columns/overviewColumns.json';
import { columnBuilder } from 'utils/helpers';
import { Flexbox, RadioButton, Sizes } from '@sede-x/shell-ds-react-framework';
import { SaveOBUPersonalizeDto, VehicleListDto, VehicleRef } from './types';
import {constructErrors, fetchVehicleSTVService} from '../../utils/helper';
import { VehicleValidator } from '../../utils/VehicleValidator';

const DEFAULT_PAGE_SIZE = 20;
interface VehicleListProps {
  customerId?: string;
}

async function fetchVehicleList(
  customerId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(customerEndPoints.getVehicleList, {
    customerId,
    pageNumber,
    pageSize
  });
}

const columnHelper = createColumnHelper<VehicleListDto>();

const actions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        {!!row.original.isEnabled && (
          <RadioButton
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            data-testid={`rowcheck-${row.index}`}
            size={Sizes.ExtraSmall}
          />
        )}
      </Flexbox>
    )
  })
];

export const VehicleListPersonalize = forwardRef<VehicleRef, VehicleListProps>(
  ({ customerId }, ref) => {
  const { values, setFieldValue } = useFormikContext<SaveOBUPersonalizeDto>();
  const [selectedRows, setSelectedRows] = useState<VehicleListDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  
  const { infoDialog } = useConfirmDialogs();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['get-registered-vehicles-without-obu', customerId, pageSize, pageNumber],
    queryFn: () =>
        fetchVehicleList(customerId as string, pageNumber, pageSize).then(
        (res) => res.data
      ),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: 'always'
  });

  const { data: cardDetailsData } = useQuery({
    queryKey: ['get-obu-vehicle-card-details-vehicleId', customerId, values.vehicleId],
    queryFn: () =>
      fetchVehicleSTVService(customerId as string, values.vehicleId).then((res) => res.data),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: 'always',
    retry: false
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  useEffect(() => {
    if (selectedRows.length) {
      const row = selectedRows[0];
      setFieldValue('vehicleId', row.vehicleId);
      setFieldValue('VehicleList', row);
    }
  }, [selectedRows]);

  useImperativeHandle(ref, () => ({
    validateVehicle() {
      const VALIDATION_TITLE = 'Validation Error';
      if (isError) {
        return false;
      }

      const stvCardNumber = cardDetailsData?.data;

      if (selectedRows) {

        const error = VehicleValidator.ValidateVehicle(selectedRows[0], stvCardNumber);

        if (error.length && error[0] !== "") {
          infoDialog(VALIDATION_TITLE, constructErrors(error));
          return false;
        }

        const err = VehicleValidator.ValidateSTVCardNumber(
          selectedRows[0],
          stvCardNumber
        );

        if (err) {
          infoDialog(VALIDATION_TITLE, err);
          return false;
        }

      } else {
        infoDialog(VALIDATION_TITLE, 'Vehicle cannot be validated.');
        return false;
      }

      return true;
    }
  }));

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="obu-list"
    >  <QueryError isLoading={isLoading} isError={isError}>
        <Table<VehicleListDto>
          data={data?.data ?? []}
          columns={
            [
              ...actions,
              ...columnBuilder(OverviewColumns.VehicleList)
            ] as ColumnDef<VehicleListDto>[]
          }
          columnSelection={false}
          exportEnabled={false}
          enableMultiRowSelection={false}
          onSelectedRowsChange={setSelectedRows}
          enableRowSelection={(row) =>
            !!row.original.isEnabled
          }
          meta={{
            getRowStyles: (row) => ({
              backgroundColor: row.original.isEnabled ? '' : '#DFDFDF'
            })
          }}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
        />
      </QueryError>
      </div>
  );
}
);

export default VehicleListPersonalize;
