import { Avatar } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const StyledAvatar = styled(Avatar)`
  div {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
  }
`;
