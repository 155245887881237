import React from 'react';
import { Text } from '@sede-x/shell-ds-react-framework';
import { useFormikContext } from 'formik';
import { SaveOBUOrderDto } from './types';


interface SummaryProps {
  searchType: string;
}

const Summary: React.FC<SummaryProps> = ({ searchType }) => {
  const { values } = useFormikContext<SaveOBUOrderDto>();
  return (
    <div
      className="bg-shellExtraPaleGrey2 py-4"
      data-testid="obu-order-summary">
      <Text size='medium' className='w-5/12 pl-20 py-4'>
        {searchType === 'orderAndPersonalizeObu' ? 'OBU Order And Personalization settings' : 'OBU Order Settings'}
      </Text>
      <div style={{ display: 'flex' }}>
        <Text className='w-4/12 pl-20 pt-2'>Product:</Text>
        <Text className='pt-2'>{values.obuProduct}</Text>
      </div><div style={{ display: 'flex' }}>
        <Text className='w-4/12 pl-20 pt-2'>Delivery address:</Text>
        <Text className='pt-2'>{values.contactPersonAddress}</Text>
      </div><div style={{ display: 'flex' }}>
        <Text className='w-4/12 pl-20 pt-2'>Delivery product:</Text>
        <Text className='pt-2'>{values.deliveryProduct}</Text>
      </div><div style={{ display: 'flex' }}>
        <Text className='w-4/12 pl-20 pt-2'>Contact person:</Text>
        <Text className='pt-2'>{values.contactPersonFullName}</Text>
      </div>
      {searchType === 'orderAndPersonalizeObu' &&
        <div style={{ display: 'flex' }}>
          <Text bold className='w-4/12 pl-20 pt-2'>Selected vehicles:</Text>
          <Text className='pt-2'>{(values.vehicleIds).join(', ')}</Text>
        </div>}
    </div>
  );
}
export default Summary;






