


import React from 'react';
import { Text } from '@sede-x/shell-ds-react-framework';
import { useFormikContext } from 'formik';
import { SaveOBUPersonalizeDto } from './types';

const Summary: React.FC = () => {
    const { values } = useFormikContext<SaveOBUPersonalizeDto>();
    const vehicleInfo = values?.VehicleList as object;

    const keys = ['registrationNumber', 'country', 'category', 'emissionClass'];
    const selectedVehicleDetails = Object.fromEntries(
        Object.entries(vehicleInfo).filter(([key]) => keys.includes(key))
    );

    return (
        <div className="bg-shellExtraPaleGrey2 py-4" data-testid="obu-personnalize-summary">
            <Text size='medium' className='pl-20 py-4'>OBU Personalization Settings</Text>
            <Text bold className='pl-20 pt-2'>Selected OBU:</Text>
            <Text className='pt-2 pl-20'>Serial number: {values.obuSerialNumber}</Text>
            <Text className='py-2 pl-20'>OBU product: {values.obuSerialNumber}</Text>
            <Text bold className='pl-20 pt-2'>Selected vehicle:</Text>
            {Object.entries(selectedVehicleDetails).map(([key, value]) => (
                <Text key={key} className='pl-20 pt-2'>{`${key.replace(/([A-Z])/g, ' $1').trim()}: ${value}`}</Text>
            ))}
        </div>
    );
}
export default Summary;






