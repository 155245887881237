import {
  Alignments,
  Modal,
  Sizes,
  Text,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { customerInstance } from 'api';
import { errorHelper } from 'utils/helpers/errorHelper';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { queryClient } from 'react-query';
import OBUSelection from './components/OBUSelection';
import ServicesSelection from './components/ServicesSelection';
import {
  ObuActionsTelepassTollServiceModificationsDto,
  ServiceRef
} from './components/types';
import DocumentSelection from './components/DocumentSelection';
import Summary from './components/Summary';

const TOTAL_STEPS = 4;
const STEP_3 = 3;
const STEP_4 = 4;

const StyledModal = styled(Modal)`
  .shell-modal-container-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
  }
  .shell-modal-container-content {
    height: 80vh;
  }
`;

interface OBUTollServiceModificationProps {
  CustomerId: string;
  handleOnCloseModal: () => void;
}

const getTitles = (step: number) => {
  switch (step) {
    case 1:
      return 'Obus';
    case 2:
      return 'Services';
    case STEP_3:
      return 'Vehicle document selection';
    case TOTAL_STEPS:
      return 'Summary';
    default:
      return '';
  }
};

const OBUTollServiceModification: React.FC<OBUTollServiceModificationProps> = ({
  CustomerId,
  handleOnCloseModal
}) => {
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const [step, setStep] = useState<number>(1);
  const serviceRef = useRef<ServiceRef>(null);

  const { errorDialog, successDialog } = useConfirmDialogs();

  const handleClose = () => {
    handleOnCloseModal();
    setStep(1);
  };

  const handleOnStepClick = (num: number, isBackClick = false) => {
    if (step === 2 && !isBackClick) {
      if (!serviceRef.current?.validateService()) {
        return;
      }

      const bulgariaServiceCode = '61';
      const checkedOperation = 'A';
      const servicesPayload: ObuActionsTelepassTollServiceModificationsDto[] =
        formRef.current?.values.servicesPayload;

      // check if bulgaria service is checked for any service
      const bulgariaService = servicesPayload?.find((service) =>
        service.OBUActionsTelepassTollServiceModificationServices?.some(
          (s) =>
            s.ServiceCode === bulgariaServiceCode &&
            s.operation === checkedOperation
        )
      );

      if (bulgariaService) {
        setStep(num);
        return;
      }
      setStep(STEP_4);
      return;
    }

    if (step === STEP_4 && isBackClick) {
      const bulgariaService = formRef.current?.values.servicesPayload?.find(
        (service: ObuActionsTelepassTollServiceModificationsDto) =>
          service.OBUActionsTelepassTollServiceModificationServices?.some(
            (s) => s.ServiceCode === '61' && s.operation === 'A'
          )
      );

      if (!bulgariaService) {
        setStep(2);
        return;
      }
    }
    setStep(num);
  };

  const renderTitle = () => (
    <div className="flex flex-col space-y-4 pl-2">
      <Text size="small">Obu update - Service Modification</Text>
      <Text prominence="strong" bold size="medium">
        {getTitles(step)}
      </Text>
    </div>
  );

  const nextDisabled = (values: FormikValues) => {
    if (step === 1) {
      return !values.selectedOBUs;
    }
    return false;
  };

  const handleOnClickBack = (stepNum: number) => {
    switch (stepNum) {
      case 2:
        formRef.current?.setFieldValue('selectedOBUs', undefined);

        formRef.current?.setFieldValue('servicesPayload', undefined);
        formRef.current?.setFieldValue('services', undefined);
        break;
      case STEP_3:
        formRef.current?.setFieldValue('servicesPayload', undefined);
        formRef.current?.setFieldValue('services', undefined);
        break;
      default:
        break;
    }
  };

  const handleSave = (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const { setSubmitting } = formikHelpers;
    const endpoint =
      'obu/actions/telepass/save-toll-service-modification-details';

    const data = {
      CustomerId,
      ObuActionsTelepassTollServiceModifications: values.servicesPayload
    };

    customerInstance
      .post(endpoint, {
        ...data
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['obu-ServiceModification-ServiceList']
        });
        queryClient.invalidateQueries({
          queryKey: ['obu-vehicle-documents']
        });
        queryClient.invalidateQueries({
          queryKey: ['obu-telepass-obuList']
        });
        successDialog('', 'Request has been successfully saved');

        handleOnCloseModal();
        setStep(1);
      })
      .catch((error) => {
        const message = 'Failed to save the request.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={handleSave}
      innerRef={formRef}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <StyledModal
          title={renderTitle()}
          open
          width="90%"
          size={Sizes.Small}
          maskClosable={false}
          mask
          onClose={handleClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          actions={[
            {
              label: 'CANCEL',
              action: () => {
                handleOnCloseModal();
              },
              props: {
                variant: Variants.Outlined
              }
            },
            {
              label: 'BACK',
              action: () => {
                handleOnClickBack(step);
                handleOnStepClick(step - 1, true);
              },
              props: {
                variant: Variants.Outlined,
                hidden: step === 1,
                style: {
                  display: step === 1 ? 'none' : ''
                }
              }
            },
            {
              label: 'NEXT',
              action: () => {
                handleOnStepClick(step + 1);
              },
              props: {
                disabled: nextDisabled(values),
                hidden: step === TOTAL_STEPS,
                style: {
                  display: step === TOTAL_STEPS ? 'none' : ''
                }
              }
            },
            {
              label: 'FINISH',
              action: () => {
                handleSubmit();
              },
              props: {
                disabled: isSubmitting,
                type: 'submit',
                hidden: step !== TOTAL_STEPS,
                style: {
                  display: step !== TOTAL_STEPS ? 'none' : ''
                },
                'aria-hidden': step !== TOTAL_STEPS
              }
            }
          ]}
        >
          <div className=" flex  flex-col flex-grow overflow-auto h-full ">
            {step === 1 && <OBUSelection customerId={CustomerId} />}
            {step === 2 && (
              <ServicesSelection ref={serviceRef} customerId={CustomerId} />
            )}
            {step === STEP_3 && <DocumentSelection />}
            {step === STEP_4 && <Summary />}
          </div>
        </StyledModal>
      )}
    </Formik>
  );
};

export default OBUTollServiceModification;
