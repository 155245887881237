import React, {ChangeEvent} from 'react';
import TextField from 'components/FormCmponents/TextField';
import { Field, useFormikContext } from 'formik';
import NumberField from 'components/FormCmponents/NumberField';
import SelectField from 'components/FormCmponents/SelectField';
import { customerEndPoints } from 'api/apiEndpoints';
import { SaveOBUDetailsDto } from './types';

const ContactData: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<SaveOBUDetailsDto>();

  function handleChangePhoneNumber(event: ChangeEvent<HTMLInputElement>) {
    const { name, value, maxLength } = event.target;
    const v = maxLength > 0 ? value.slice(0, maxLength) : value;
    setFieldValue(name, v);
  }

  return (
    <div
      className="grid grid-cols-1 md:grid-cols-1 grow overflow-y-auto px-10 py-10 bg-shellExtraPaleGrey2"
      data-testid="obu-list">
        <div className='w-3/4'>
        <Field
          id="contactName"
          name="contactName"
          placeholder="Type here"
          label="Contact Name"
          labelPosition="left"
          component={TextField}
          value={values.contactName}
        />
        <Field
          id="contactSurname"
          name="contactSurname"
          placeholder="Type here"
          label="Contact Surname"
          labelPosition="left"
          component={TextField}
          value={values.contactSurname}
        />
        <Field
          id="a2"
          name="a2"
          placeholder="Type here"
          label="Notification Language"
          labelPosition="left"
          component={SelectField}
          dataEndPoint={customerEndPoints.kmasterLanguages}
          dataField="a2"
          dataLabelField="name"
          onOption
          mandatory
          isCustomerInstance
        />
        <Field
          id="contactPhone"
          name="contactPhone"
          placeholder="Type here"
          label="Contact Phone"
          labelPosition="left"
          component={NumberField}
          mandatory
          maxLength={10}
          onChange={handleChangePhoneNumber}
        />
        <Field
          id="contactEmail"
          name="contactEmail"
          placeholder="Type here"
          label="Email"
          labelPosition="left"
          component={TextField}
          mandatory
        />
        </div>
    </div>);
}
export default ContactData;
