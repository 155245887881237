import React from 'react';
import { Text } from '@sede-x/shell-ds-react-framework';

const OrderSteps: React.FC = () => {

    const steps = [
        'Steps',
        'Contract Selection',
        'Address and Order Type selection',
        'OBU Selection',
        'KMaster services selection',
        'Contact Data',
        'Summary'
    ];
    return (
        <div
            className="grow overflow-y-auto bg-shellExtraPaleGrey2"
            data-testid="obu-list"
        >
            <Text className="pl-20 pb-5 pt-5" size="medium">Order Steps</Text>
            {steps.map((data) => <ul key={data} className="list-disc pl-24 pb-3">
                <li className="list-inside">{data}</li>
            </ul>)}
        </div>
    )
};
export default OrderSteps;
