import QueryError from 'components/QueryError';
import { useMutation, useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { FormikValues, useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import { useSdsPagination } from 'hooks/use-pagination';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { AdditionalCustomerDto, CustomerRef } from './types';

async function fetchAssociatedCustomers(
  customerId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post('obu/actions/t4e/get-obu-associated-customers', {
    customerId,
    pageNumber,
    pageSize
  });
}

async function fetchExternalCustomerId(customerId: string) {
  return customerInstance.post('obu/actions/t4e/get-obu-external-customerId', {
    customerId
  });
}

const DEFAULT_PAGE_SIZE = 20;
interface CustomerListProps {
  customerId: string;
}

const CustomerList = forwardRef<CustomerRef, CustomerListProps>(
  ({ customerId }, ref) => {
    const { setFieldValue } = useFormikContext<FormikValues>();
    const [selectedRows, setSelectedRows] = useState<AdditionalCustomerDto[]>(
      []
    );
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const { infoDialog } = useConfirmDialogs();

    const { data, isLoading, isError } = useQuery({
      queryKey: [
        'get-obu-associated-customers',
        customerId,
        pageNumber,
        pageSize
      ],
      queryFn: () =>
        fetchAssociatedCustomers(customerId, pageNumber, pageSize).then(
          (res) => res.data
        ),
      staleTime: STALE_TIME,
      refetchOnWindowFocus: 'always',
      keepPreviousData: true
    });

    const {
      mutate: getExternalCustomerId,
      data: externalCustomerIdData,
      isError: externalCustomerIdFetchError
    } = useMutation({
      mutationKey: ['get-external-customer-id'],
      mutationFn: (customId: string) =>
        fetchExternalCustomerId(customId).then((res) => res.data)
    });

    const handleChangePagination = (
      newPageNumber: number,
      newPageSize: number
    ) => {
      setPageNumber(newPageNumber);
      setPageSize(newPageSize);
    };

    const paginationProps = useSdsPagination(handleChangePagination);

    useEffect(() => {
      if (selectedRows.length) {
        const row = selectedRows[0];
        setFieldValue('customerIdTo', row.customerId);
        setFieldValue('customerNumberTo', row.customerNo);
        getExternalCustomerId(row.customerId);
      }
    }, [selectedRows]);

    useImperativeHandle(ref, () => ({
      validateCustomer() {
        const VALIDATION_TITLE = 'Validation Error';
        if (isError || externalCustomerIdFetchError) {
          infoDialog(VALIDATION_TITLE, 'Customer cannot be validated');
          return false;
        }

        const selectedCustomer = selectedRows[0];
        if (selectedCustomer) {
          const externalCustomerId = externalCustomerIdData?.data[0];

          if (!externalCustomerId) {
            const message = `There is no external customer ID for current customer: ${selectedCustomer.name}`;
            infoDialog(VALIDATION_TITLE, message);
            return false;
          }

          if (customerId === selectedCustomer.customerId) {
            const message = 'Unable transfer obu to same customer';
            infoDialog(VALIDATION_TITLE, message);
            return false;
          }
        }

        return true;
      }
    }));

    return (
      <div
        className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
        data-testid="obu-list"
      >
        <QueryError isLoading={isLoading} isError={isError}>
          <Table<AdditionalCustomerDto>
            data={data?.data ?? []}
            columns={
              getOverviewColumns(
                'OBUAdditionalCustomers'
              ) as ColumnDef<AdditionalCustomerDto>[]
            }
            columnSelection={false}
            exportEnabled={false}
            enableMultiRowSelection={false}
            onSelectedRowsChange={setSelectedRows}
            paginationData={{
              ...paginationProps,
              total: data?.count
            }}
            resetSelectedRowsOnPageChange={false}
            getRowId={(row) => row.customerId}
          />
        </QueryError>
      </div>
    );
  }
);

export default CustomerList;
