import { Modal, Sizes, Alignments, Variants, Text } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import React, { useState, useRef } from 'react';
import { customerEndPoints } from 'api/apiEndpoints';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { errorHelper } from 'utils/helpers/errorHelper';
import { customerInstance } from 'api/customerInstance';
import _ from 'lodash';
import OrderSteps from './components/OrderSteps';
import ContractSelection from './components/ContractSelection';
import AddressesSelection from './components/AddressesSelection';
import OBUSelection from './components/OBUSelection';
import ServiceOverview from './components/ServiceOverview';
import ContactData from './components/ContactData';
import Summary from './components/Summary';
import { SaveOBUDetailsDto } from './components/types';
import { validateDetails } from './components/ValidateContactDetails';

const StyledModal = styled(Modal)`
  .shell-modal-container-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`;

const TOTAL_STEPS = 7;
const STEP_3 = 3;
const STEP_5 = 5;
const STEP_6 = 6;
const STEP_7 = 7;
const getTitles = (step: number) => {
  switch (step) {
    case 1:
      return 'Select KMaster Order';
    case 2:
      return 'Contract selection';
    case STEP_3:
      return 'Address and Order Type selection';
    case 4:
      return 'OBU Selection';
    case STEP_5:
      return 'Service Overview';
    case STEP_6:
      return 'Contact Data';
    case STEP_7:
      return 'Summary';
    default:
      return 'Select KMaster Order';
  }
};

interface OBUActionsProps {
  customerId: string;
  setSelectedMenu: (value: string) => void;
}

const OBUActions: React.FC<OBUActionsProps> = ({ customerId, setSelectedMenu }) => {
  const formRef = useRef<FormikProps<SaveOBUDetailsDto>>(null);
  const [step, setStep] = useState<number>(1);
  const { errorDialog, successDialog } = useConfirmDialogs();

  const handleOnCloseModal = () => {
    setSelectedMenu('');
    setStep(1);
  };

  const handleOnStepClick = (num: number) => {
    setStep(num);
  };

  const handleSave = (
    values: SaveOBUDetailsDto,
    formikHelpers: FormikHelpers<SaveOBUDetailsDto>
  ) => {
    const { setSubmitting } = formikHelpers;
    const endpoint = customerEndPoints.savekmasterDetails;
    const payload = { ...values };

    const data = {
      customerId,
      contactName: payload.contactName,
      contactSurname: payload.contactSurname,
      notificationLanguageCode: payload.a2,
      contactEmail: payload.contactEmail,
      contactPhone: (payload.contactPhone).toString(),
      obuActionsTelepassKmaster: values.OBUKmasterDetails
    }
    customerInstance
      .post(endpoint, {
        ...data,
      })
      .then(() => {
        successDialog('', 'Request has been successfully saved');
        setSelectedMenu('');
        setStep(1);
      })
      .catch((error) => {
        const message = 'Failed to save the request.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };


  const handleOnClickBack = (stepNum: number) => {
    switch (stepNum) {
      case 2:
        formRef.current?.setFieldValue('contractId', undefined)
        break;
      case STEP_3:
        formRef.current?.setFieldValue('contactId', undefined)
        formRef.current?.setFieldValue('obuTypeId', undefined)
        formRef.current?.setFieldValue('OBUType', undefined)
        break;
      case 4:
        formRef.current?.setFieldValue('vehicleId', undefined);
        formRef.current?.setFieldValue('obuId', undefined);
        formRef.current?.setFieldValue('pan', undefined);
        formRef.current?.setFieldValue('obuFullNumber', undefined);
        formRef.current?.setFieldValue('obuTypeSTV', undefined);
        formRef.current?.setFieldValue('obuNumber', undefined);
        break;
      case STEP_6:
        formRef.current?.setFieldValue('contactPhone', undefined);
        formRef.current?.setFieldValue('contactEmail', undefined);
        formRef.current?.setFieldValue('a2', undefined);
        break;
      default:
        break;
    }
  };

  const nextDisabled = (values: SaveOBUDetailsDto) => {
    const formError = validateDetails(values);
    const isValid = [values.contactName, values.contactSurname, values.contactPhone, values.contactEmail, values.a2].every(Boolean);
    switch (step) {
      case 2:
        return !values.contractId;
      case STEP_3:
        return !values.contactId || !values.obuTypeId;
      case 4:
        return !values.selectedOBUs;
      case STEP_6:
        return !isValid || !_.isEmpty(formError);
      default:
        return false;
    }
  };

  const renderTitle = () =>
    <div className='flex flex-col space-y-4 pl-2'><Text size="small">KMaster Registration Deregistration wizard</Text>
      <Text prominence='strong' bold size="medium">{getTitles(step)}</Text>
    </div>


  return (
    <div>
      <Formik<SaveOBUDetailsDto>
        initialValues={{} as SaveOBUDetailsDto}
        onSubmit={handleSave}
        innerRef={formRef}
        validate={(values) => validateDetails(values)}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <StyledModal
            open
            title={renderTitle()}
            width="60%"
            size={Sizes.Small}
            maskClosable={false}
            mask
            onClose={handleOnCloseModal}
            contentScrollable
            actionsAlignment={Alignments.Right}
            centered
            actions={[
              {
                label: 'CANCEL',
                action: () => {
                  handleOnCloseModal();
                },
                props: {
                  variant: Variants.Outlined,
                }
              },
              {
                label: 'BACK',
                action: () => {
                  handleOnClickBack(step);
                  handleOnStepClick(step - 1);
                },
                props: {
                  variant: Variants.Outlined,
                  hidden: step === 1,
                  style: {
                    display: step === 1 ? 'none' : '',
                  }
                }
              },
              {
                label: 'NEXT',
                action: () => {
                  handleOnStepClick(step + 1);
                },
                props: {
                  disabled: nextDisabled(values),
                  hidden: step === TOTAL_STEPS,
                  style: {
                    display: step === TOTAL_STEPS ? 'none' : ''
                  }
                }
              },
              {
                label: 'FINISH',
                action: () => {
                  handleSubmit();
                },
                props: {
                  disabled: isSubmitting,
                  type: 'submit',
                  hidden: step !== TOTAL_STEPS,
                  style: {
                    display: step !== TOTAL_STEPS ? 'none' : ''
                  },
                  'aria-hidden': step !== TOTAL_STEPS
                }
              }
            ]}
          >
            {step === 1 && (<OrderSteps />)}
            {step === 2 && (<ContractSelection customerId={customerId} />)}
            {step === STEP_3 && (<AddressesSelection customerId={customerId} />)}
            {step === 4 && (<OBUSelection customerId={customerId} />)}
            {step === STEP_5 && (<ServiceOverview />)}
            {step === STEP_6 && (<ContactData />)}
            {step === STEP_7 && (<Summary />)}
          </StyledModal>
        )}
      </Formik>
    </div>
  );
};

export default OBUActions;
