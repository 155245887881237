import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import {
  getOverviewColumns,
} from 'components/Table/columns/overviewColumns';
import { useState, useEffect } from 'react';
import { customerEndPoints } from 'api/apiEndpoints';
import { ColumnDef } from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import { SaveOBUDetailsDto, ContractDto } from './types';

const DEFAULT_PAGE_SIZE = 20;
interface ContractSelectionProps {
  customerId?: string;
}

const ContractSelection: React.FC<ContractSelectionProps> = ({ customerId }) => {
  const { setFieldValue } = useFormikContext<SaveOBUDetailsDto>();
  const [selectedRows, setSelectedRows] = useState<ContractDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { data, isLoading, isError } = useQuery(
    ['get-kmaster-contracts', customerId, pageSize, pageNumber],
    async () => {
      const result = await customerInstance.post(
        customerEndPoints.kmasterContracts,
        {
          pageNumber,
          pageSize,
          customerId,
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return result.data;
    },
    {
      staleTime: STALE_TIME,
      refetchOnWindowFocus: 'always'
    }
  );

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  useEffect(() => {
    if (selectedRows.length) {
      const row = selectedRows[0];
      setFieldValue('contractId', row.contractId);
    }
  }, [selectedRows]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="obu-list"
    >      <QueryError isLoading={isLoading} isError={isError}>
        <Table<ContractDto>
          data={data?.data ?? []}
          columns={getOverviewColumns('OBUContractSelection') as ColumnDef<ContractDto>[]}
          columnSelection={false}
          exportEnabled={false}
          enableMultiRowSelection={false}
          onSelectedRowsChange={setSelectedRows}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
        />
      </QueryError>
    </div>
  );
};

export default ContractSelection;
