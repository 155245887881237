import React, { ReactNode } from 'react';
import {
  Heading,
  Section,
  Text,
  TextTypes
} from '@sede-x/shell-ds-react-framework';
import { FormikValues, useFormikContext } from 'formik';

const BoldText = ({ children }: { children: ReactNode }) => (
  <span className="font-bold">{children}</span>
);

const Summary: React.FC = () => {
  const { values } = useFormikContext<FormikValues>();

  return (
    <div className="flex flex-col grow overflow-y-auto p-5 bg-shellExtraPaleGrey2 gap-8">
      <Heading bold type={TextTypes.H2}>
        OBU Transfer Settings
      </Heading>
      <Section>
        <Heading type={TextTypes.H3} bold gutter>
          Selected OBU
        </Heading>
        <Text>
          Serial Number: <BoldText>{values?.obuSerialNumber}</BoldText>
        </Text>
        <Text>
          OBU Product: <BoldText>{values?.obuProductType}</BoldText>
        </Text>
      </Section>
      <Section>
        <Heading type={TextTypes.H3} bold gutter>
          Selected Vehicle
        </Heading>
        <Text>
          Registration Plate Number:{' '}
          <BoldText>{values?.registrationNumber}</BoldText>
        </Text>
        <Text>
          Country: <BoldText>{values?.country}</BoldText>
        </Text>
      </Section>
      <Section>
        <Heading type={TextTypes.H3} bold gutter>
          Selected Vehicle Documents (Needed documents will be uploaded to the
          storage)
        </Heading>
        <Text>{values?.documents[0]?.fileName}</Text>
        <Text>{values?.documents[1]?.fileName}</Text>
      </Section>
    </div>
  );
};
export default Summary;
