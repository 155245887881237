import React, { ReactNode } from 'react';
import { Text } from '@sede-x/shell-ds-react-framework';
import { FormikValues, useFormikContext } from 'formik';
import { ServicesDto } from './types';
import { labelMapping } from './ServicesSelection';

const BoldText = ({ children }: { children: ReactNode }) => (
  <span className="font-bold">{children}</span>
);

const Summary: React.FC = () => {
  const { values } = useFormikContext<FormikValues>();

  return (
    <div className="flex flex-col grow overflow-y-auto p-5 bg-shellExtraPaleGrey2">
      <div className="flex">
        <Text>
          OBU Action:
          <BoldText> Toll Service Modification</BoldText>
        </Text>
      </div>

      {values?.services?.map((service: ServicesDto) => (
        <div className=" mt-6" key={service.obuId}>
          <div>
            <span>
              <span>
                Telepass number:{' '}
                <BoldText>{service.telepassObuNumber}; </BoldText>
              </span>
              <span>
                Type: <BoldText> {service.obuType};</BoldText>
              </span>
              <span>
                Vehicle registration:{' '}
                <BoldText>{service.carRegistration}</BoldText>
              </span>
            </span>
          </div>
          <div>
            <span>
              Registered tolls:
              {Object.keys(labelMapping).map((key) => (
                <span key={key}>
                  {service[key as keyof ServicesDto] && (
                    <span className="ml-2">
                      {labelMapping[key]}:{' '}
                      <BoldText>
                        {service[key as keyof ServicesDto] && 'Yes'}
                      </BoldText>
                      ,
                    </span>
                  )}
                </span>
              ))}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Summary;
