import {
  THead,
  TR as SdsTr,
  TH as SdsTh,
  TBody,
  TD as SdsTd,
  Accordion,
  Button,
  Icons
} from '@sede-x/shell-ds-react-framework';
import { FormikValues, useFormikContext } from 'formik';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { customerInstance } from 'api';
import { useQuery } from '@tanstack/react-query';
import QueryError from 'components/QueryError';
import { createMap, viewPdf } from 'utils/helpers';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { errorHelper } from 'utils/helpers/errorHelper';
import {
  DocumentsDto,
  ServicesDto,
  VehicleDocumentMap
} from '../../OBUTollServiceModification/components/types';
import { FileInput } from '../../OBUTollServiceModification/components/styles';
import { ObuActionsTelepassTollServiceReplacementDto } from './types';

const StyledAccordion = styled(Accordion)`
  .shell-accordion-content-box {
    padding: 0;
  }
`;

async function fetchVehicleDocuments(vehicleIds: string[]) {
  return customerInstance.post('document/get-vehicle-documents', {
    vehicleIds
  });
}

const DocumentSelection: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [documentMap, setDocumentMap] = useState<VehicleDocumentMap | null>(
    null
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { errorDialog } = useConfirmDialogs();

  const vehicleIds = values.services
    ?.filter((service: ServicesDto) => service.bulgariaNew)
    .map((service: ServicesDto) => service.vehicleId);

  const {
    isError,
    data: vehicleDocument,
    isLoading
  } = useQuery({
    queryKey: ['obu-vehicle-documents', ...(vehicleIds || [])],
    queryFn: () => fetchVehicleDocuments(vehicleIds).then((res) => res.data)
  });

  const handleViewFile = (document: DocumentsDto) => {
    if (document.storageId) {
      customerInstance
        .post('document/view-file', {
          storageId: document.storageId
        })
        .then((response) => {
          // To be implemented
          const { data: file } = response;
          viewPdf(file.FileContent as string);
        })
        .catch((error) => {
          const message = 'Document can not be opened...Something is wrong';
          errorDialog('Error', errorHelper(error, message));
        });
    } else {
      viewPdf(document.fileContent as string);
    }
  };

  useEffect(() => {
    if (vehicleDocument?.data) {
      setDocumentMap(
        createMap(
          vehicleDocument.data,
          'vehicleId'
        ) as unknown as VehicleDocumentMap
      );
    }
  }, [vehicleDocument]);

  useEffect(() => {
    if (documentMap) {
      const servicesPayload: ObuActionsTelepassTollServiceReplacementDto[] =
        values?.servicesPayload;

      servicesPayload.forEach((service) => {
        const vehicleId = service.VehicleId;
        const vehicleDoc = documentMap[vehicleId];
        if (vehicleDoc) {
          service.documents = vehicleDoc.documentDetails.map((document) => ({
            documentTypeId: document.documentTypeId,
            storageId: document.storageId,
            fileContent: null,
            fileName: document.documentFileName,
            fileExtension: null
          }));
        }
      });
      setFieldValue('servicesPayload', servicesPayload);
    }
  }, [documentMap]);

  const readUploadFile = (
    event: ChangeEvent<HTMLInputElement>,
    document: DocumentsDto,
    vehicleId: string
  ) => {
    event.preventDefault();
    if (event.target.files) {
      const file = event.target.files[0];
      processFile(file, document, vehicleId);

      event.target.value = ''; // clear the file input
    }
  };

  const processFile = (
    file: File,
    document: DocumentsDto,
    vehicleId: string
  ) => {
    // support only pdf files
    if (file.type !== 'application/pdf') {
      errorDialog('', 'Only PDF files are allowed.');
      return;
    }

    const MAX_FILE_SIZE = 2097152; // 2 MB in bytes
    if (file?.size > MAX_FILE_SIZE) {
      errorDialog('', 'File size exceeds 2 MB. Please choose a smaller file.');
    } else {
      const reader = new FileReader();

      reader.onload = () => {
        const resultString = reader.result as string;

        const base64Data = resultString.replace(/^data:(.*,)?/, '');
        const servicesPayload: ObuActionsTelepassTollServiceReplacementDto[] =
          values?.servicesPayload;

        servicesPayload.forEach((service) => {
          if (service.VehicleId === vehicleId) {
            service.documents = service.documents?.map((doc) => {
              if (doc.documentTypeId === document.documentTypeId) {
                return {
                  ...doc,
                  storageId: null,
                  fileContent: base64Data,
                  fileName: file.name,
                  fileExtension: 'pdf'
                };
              }
              return doc;
            });
          }
        });
        setFieldValue('servicesPayload', servicesPayload);
      };

      reader.readAsDataURL(file);
    }
  };

  const filteredServices = values?.servicesPayload?.filter(
    (vehicle: ObuActionsTelepassTollServiceReplacementDto) =>
      vehicleIds?.includes(vehicle.VehicleId)
  );

  return (
    <div
      className="flex flex-col grow overflow-y-auto p-3 bg-shellExtraPaleGrey2"
      data-testid="obu-selection-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <table className="w-full bg-white">
          <THead>
            <SdsTr>
              <SdsTh>Document type</SdsTh>
              <SdsTh>File</SdsTh>
              <SdsTh>Action</SdsTh>
            </SdsTr>
          </THead>
          <TBody>
            <SdsTr>
              <SdsTd colSpan={3} className="!p-0">
                <StyledAccordion
                  fullWidth
                  iconSet="chevron"
                  defaultActiveKey={
                    filteredServices?.map(
                      (service: ObuActionsTelepassTollServiceReplacementDto) =>
                        service.VehicleId
                    ) || []
                  }
                  items={filteredServices?.map(
                    (vehicle: ObuActionsTelepassTollServiceReplacementDto) => ({
                      key: vehicle.VehicleId,
                      label: `Vehicle LPN: ${
                        documentMap?.[vehicle.VehicleId]?.licensePlate
                      }`,
                      children: (
                        <table className="w-full">
                          <TBody>
                            {vehicle.documents?.map((document) => (
                              <SdsTr key={document.documentTypeId}>
                                <SdsTd>
                                  {document.documentTypeId ===
                                  'c3a0fa3b-c4d9-4c59-83a0-44c8c87decd6'
                                    ? 'Vehicle Registration documents'
                                    : 'Vehicle Euro Certificate document'}
                                </SdsTd>
                                <SdsTd>{document.fileName}</SdsTd>
                                <SdsTd>
                                  <div className="flex gap-2">
                                    <Button
                                      size="small"
                                      className="cursor-pointer"
                                      data-testid="upload-button"
                                      onKeyDown={(e) => {
                                        if (
                                          (e.key === 'Enter' ||
                                            e.code === 'Space') &&
                                          fileInputRef.current
                                        ) {
                                          fileInputRef.current.click();
                                        }
                                      }}
                                    >
                                      <div className="flex gap-2">
                                        <div className="">
                                          <Icons.UploadCloud
                                            width={15}
                                            height={15}
                                          />
                                        </div>
                                        <div>Select file</div>
                                        <FileInput
                                          type="file"
                                          id="file-input"
                                          data-testid="file-input"
                                          name="file-input"
                                          accept=".pdf"
                                          onChange={(e) =>
                                            readUploadFile(
                                              e,
                                              document,
                                              vehicle.VehicleId
                                            )
                                          }
                                          tabIndex={-1}
                                          ref={fileInputRef}
                                          aria-hidden="true"
                                        />
                                      </div>
                                    </Button>
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      onClick={() => handleViewFile(document)}
                                      data-testid="view-file"
                                    >
                                      View file
                                    </Button>
                                  </div>
                                </SdsTd>
                              </SdsTr>
                            ))}
                          </TBody>
                        </table>
                      )
                    })
                  )}
                />
              </SdsTd>
            </SdsTr>
          </TBody>
        </table>
      </QueryError>
    </div>
  );
};

export default DocumentSelection;
