import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { useFormikContext, Field } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import SelectField from 'components/FormCmponents/SelectField';
import { useState, useEffect } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import { Flexbox, RadioButton, Sizes } from '@sede-x/shell-ds-react-framework';
import { customerEndPoints } from 'api/apiEndpoints';
import { AddressSelectionDto } from './types';

const DEFAULT_PAGE_SIZE = 20;

async function fetchCustomerContact(
  customerId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(
    'obu/actions/telepass/get-obu-replacement-customer-contacts',
    {
      customerId,
      pageNumber,
      pageSize
    }
  );
}

const columnHelper = createColumnHelper<AddressSelectionDto>();

const actions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        {row.original.isRowAvailable && (
          <RadioButton
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            data-testid={`rowcheck-${row.index}`}
            size={Sizes.ExtraSmall}
          />
        )}
      </Flexbox>
    )
  })
];

interface AddressesSelectionProps {
  customerId?: string;
}

const AddressesSelection: React.FC<AddressesSelectionProps> = ({
  customerId
}) => {
  const { setFieldValue } = useFormikContext();
  const [selectedRows, setSelectedRows] = useState<AddressSelectionDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['obu-telepass-addressList', customerId, pageSize, pageNumber],
    queryFn: () =>
      fetchCustomerContact(customerId as string, pageNumber, pageSize).then(
        (res) => res.data
      ),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: 'always'
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  useEffect(() => {
    if (selectedRows.length) {
      const [selectedContact] = selectedRows;
      setFieldValue('contact', selectedContact);
    } else {
      setFieldValue('contact', undefined);
    }
  }, [selectedRows]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="obu-addresses-selection-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <Table<AddressSelectionDto>
          data={data?.data ?? []}
          columns={
            [
              ...actions,
              ...getOverviewColumns('OBUReplacementAddress')
            ] as ColumnDef<AddressSelectionDto>[]
          }
          columnSelection={false}
          exportEnabled={false}
          enableMultiRowSelection={false}
          onSelectedRowsChange={setSelectedRows}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
          meta={{
            getRowStyles: (row) => ({
              backgroundColor: row.original.isRowAvailable ? '' : '#DFDFDF'
            })
          }}
        />
        <div className="w-1/3">
          <Field
            id="obuType"
            name="obuType"
            placeholder="Select OBU type"
            label="OBU Type"
            component={SelectField}
            mandatory
            dataEndPoint={customerEndPoints.obuReplacementObuTypes}
            dataField="obuTypeId"
            dataLabelField="obuTypeDescription"
            onOption
            isCustomerInstance
          />
        </div>
      </QueryError>
    </div>
  );
};

export default AddressesSelection;
