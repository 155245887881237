import React, { useEffect, useMemo } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import {
  Column,
  ColumnDef,
  createColumnHelper,
  Row
} from '@tanstack/react-table';
import { Checkbox, Flexbox } from '@sede-x/shell-ds-react-framework';
import Table from 'components/Table/Table';
import { ReturnedObuDto } from './types';

interface RenderColumnProps {
  row: Row<ReturnedObuDto>;
  column: Column<ReturnedObuDto>;
  onChangeCheckbox: (
    rowIndex: number,
    columnId: string,
    value: unknown
  ) => void;
}

const RenderColumn: React.FC<RenderColumnProps> = ({
  row,
  column,
  onChangeCheckbox
}) => (
  <Flexbox gap="12px" alignItems="center" justifyContent="center">
    <Checkbox
      checked={row.original.returnStatus === 1}
      onChange={(e) => onChangeCheckbox(row.index, column.id, e.target.checked)}
      data-testid={`${column.id}-${row.index}`}
    />
  </Flexbox>
);

const ReturnedObus: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [returnedObus, setReturnedObus] = React.useState<ReturnedObuDto[]>([]);
  const columnHelper = createColumnHelper<ReturnedObuDto>();

  useEffect(() => {
    if (values?.selectedOBUs) {
      setReturnedObus(
        values?.selectedOBUs.map((obu: ReturnedObuDto) => ({
          ...obu,
          returnStatus: 0
        }))
      );
    }
  }, [values?.selectedOBUs]);

  const onChangeCheckbox = (
    rowIndex: number,
    columnId: string,
    value: unknown
  ) => {
    const cellValue = value ? 1 : 0;
    setReturnedObus((prev) =>
      prev.map((row, index) =>
        index === rowIndex ? { ...row, [columnId]: cellValue } : row
      )
    );
  };

  useEffect(() => {
    setFieldValue('returnedObus', returnedObus);
  }, [returnedObus]);

  const columns = useMemo<ColumnDef<ReturnedObuDto>[]>(
    () => [
      {
        header: 'Telepass OBU Number',
        id: 'telepassObuNumber',
        accessorKey: 'telepassObuNumber'
      },
      {
        header: 'Registration, Number',
        id: 'carRegistration',
        accessorKey: 'carRegistration'
      },
      columnHelper.display({
        id: 'returnStatus',
        header: 'Returned',
        size: 20,
        cell: ({ row, column }) =>
          RenderColumn({ row, column, onChangeCheckbox })
      })
    ],
    [values?.selectedOBUs]
  );

  return (
    <div className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2">
      <Table<ReturnedObuDto>
        data={returnedObus}
        columns={columns}
        columnSelection={false}
        exportEnabled={false}
        stickyColumns={['obuType']}
      />
    </div>
  );
};

export default ReturnedObus;
