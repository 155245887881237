import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { FormikValues, useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import { useState, useEffect } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import { Flexbox, SelectionCheckbox } from '@sede-x/shell-ds-react-framework';
import _ from 'lodash';
import { ObuListDto } from './types';

const DEFAULT_PAGE_SIZE = 20;

async function fetchCustomerObus(
  customerId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post('obu/actions/telepass/get-obus', {
    customerId,
    pageNumber,
    pageSize
  });
}

interface OBUSelectionProps {
  customerId: string;
}

const columnHelper = createColumnHelper<ObuListDto>();

const actions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        {row.original.isRowAvailable && (
          <SelectionCheckbox
            checked={row.getIsSelected()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
            data-testid={`rowcheck-${row.index}`}
          />
        )}
      </Flexbox>
    )
  })
];

const OBUSelection: React.FC<OBUSelectionProps> = ({ customerId }) => {
  const { setFieldValue } = useFormikContext<FormikValues>();
  const [selectedRows, setSelectedRows] = useState<ObuListDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { isError, data, isLoading } = useQuery({
    queryKey: ['obu-telepass-obuList', customerId, pageSize, pageNumber],
    queryFn: () =>
      fetchCustomerObus(customerId, pageNumber, pageSize).then(
        (res) => res.data
      ),
    staleTime: STALE_TIME,
    keepPreviousData: true
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  useEffect(() => {
    if (selectedRows.length) {
      setFieldValue('selectedOBUs', selectedRows);
    } else {
      setFieldValue('selectedOBUs', undefined);
    }
  }, [selectedRows]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="obu-selection-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <div>* Maximum 20 OBUs can be selected at a time.</div>
        <Table<ObuListDto>
          data={data?.data ?? []}
          columns={
            [
              ...actions,
              ...getOverviewColumns('OBUServiceModification')
            ] as ColumnDef<ObuListDto>[]
          }
          columnSelection={false}
          exportEnabled={false}
          enableMultiRowSelection
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
          enableRowSelection={(row) =>
            row.original.isRowAvailable && selectedRows.length < 20
          }
          meta={{
            getRowStyles: (row) => ({
              backgroundColor: row.original.isRowAvailable ? '' : '#DFDFDF'
            })
          }}
          resetSelectedRowsOnPageChange={false}
          onSelectedRowsChange={(rows, rowSelectionState) => {
            setSelectedRows((prevSelectedRows) => {
              const allSelectedRows = [...prevSelectedRows, ...rows];
              const uniqueSelectedRows = _.uniqBy(allSelectedRows, 'obuId');
              return uniqueSelectedRows.filter(
                (row) => rowSelectionState?.[row.obuId]
              );
            });
          }}
          getRowId={(row) => row.obuId}
        />
        <div>{selectedRows.length} of 20 items selected</div>
      </QueryError>
    </div>
  );
};

export default OBUSelection;
