import React from 'react';
import { Text } from '@sede-x/shell-ds-react-framework';
import { useFormikContext } from 'formik';
import { SaveDepersonalizeObuDto } from './types';

const Summary: React.FC = () => {
    const { values } = useFormikContext<SaveDepersonalizeObuDto>();
    return (
        <div className="bg-shellExtraPaleGrey2 py-4" data-testid="obu-personnalize-summary">
            <Text size='medium' className='pl-20 py-4'>OBU Depersonalization Settings</Text>
            <Text bold className='pl-20 pt-2'>Selected OBU:</Text>
            <Text className='pt-2 pl-20'>Serial number: {values.obuSerialNumber}</Text>
            <Text className='py-2 pl-20'>OBU product: {values.obuProductType}</Text>
            <Text bold className='pl-20 pt-2'>Depersonalization Reason: {values?.selectedReason}</Text>
            {values?.contractChangeData?.map((data) =>
                <div className='py-2' key={data.obuid}>
                    <Text key={data.productName} className='pl-20 pt-2'>{data.productName}</Text>
                    <Text key={data.newContractId} className='pl-20 pt-2'>New ContractId: {data.newContractId}</Text>
                    <Text key={data.oldContractId} className='pl-20 pt-2'>Old ContractId: {data.oldContractId}</Text>
                </div>
            )}
        </div>
    );
}
export default Summary;
