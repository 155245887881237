import React, { ReactElement, useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { customerEndPoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import { STALE_TIME } from 'react-query';
import { Text } from '@sede-x/shell-ds-react-framework';
import { Container } from './Styles';
import { customerMenu } from './Menulist';
import SideMenu from './SideMenu/SideMenu';
import { SelectedSideMenu } from './components/Customer/types';
import CustomerData from './components/Customer/CustomerData/CustomerData';
import ObuList from './components/OBU/ObuList/ObuList';
import ObuDetails from './components/OBU/ObuDetails/ObuDetails';
import AddressList from './components/Address/AddressList/AddressList';
import VehicleList from './components/Vehicle/VehicleList/VehicleList';
import { AdditionalAccounts } from './components/AdditionalAccounts/AdditionalAccounts';
import ContractList from './components/Contracts/ContractList/ContractList';
import ContactList from './components/Contact/ContactList/ContactList';
import { VehicleDetails } from './components/Vehicle/VehicleDetails/VehicleDetails';
import ContractDetails from './components/Contracts/ContractDetails/ContractDetails';
import CardList from './components/Cards/CardList/CardList';
import CardDetails from './components/Cards/CardDetails/CardDetails';
import Actions from './components/Actions/Actions';

const supportedTypes = ['customer', 'vehicle', 'card', 'obu', 'contract'];

async function fetchCustomerById(id: string) {
  const { data } = await customerInstance.post(
    customerEndPoints.customerDetails,
    {
      customerId: id
    }
  );
  return data;
}

const CustomerDetails: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const queryParamValue = queryParams.get('type');
  const [menu, setMenu] = useState<string>(queryParamValue ?? '');
  const { customerId, id } = useParams<{ customerId: string; id?: string }>();

  const { data: customerData } = useQuery({
    queryKey: ['customers', customerId],
    queryFn: () => fetchCustomerById(customerId ?? ''),
    staleTime: STALE_TIME,
    enabled: !!customerId,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (queryParamValue && !supportedTypes.includes(queryParamValue)) {
      navigate('/', { replace: true });
    }
  }, [navigate, location]);

  const handleMenuClick = (selectedMenu: SelectedSideMenu) => {
    const { key } = selectedMenu;
    setMenu(key);
  };

  return (
    <Container className="flex p-3 flex-col">
      {customerData?.status === 'Blocked' && (
        <div className="p-1 bg-shellRed ">
          <Text className="!text-white " bold>
            Blocked
          </Text>
        </div>
      )}
      <div className="flex overflow-y-scroll grow no-scrollbar">
        <div className="flex flex-shrink-0 flex-col bg-white mr-2 overflow-y-scroll no-scrollbar">
          <SideMenu
            menuList={customerMenu}
            selectedMenu={menu}
            setMenu={handleMenuClick}
          />
          {customerData?.customerID && <Actions customer={customerData} />}
        </div>
        <MenuDetails
          menu={menu}
          customerID={String(customerId)}
          id={id}
          setMenu={setMenu}
        />
      </div>
    </Container>
  );
};

const MenuDetails: React.FC<{
  menu: string;
  customerID: string;
  id?: string;
  setMenu: (menu: string) => void;
}> = ({ menu, customerID, id, setMenu }) => {
  const navigate = useNavigate();

  const handleNavigateToCustomerDetails = () => {
    navigate(`/customer/${customerID}?type=customer`, { replace: true });
    setMenu('customer');
  };

  let children: ReactElement | null;

  switch (menu) {
    case 'customer':
      children = <CustomerData customerId={customerID} />;
      break;
    case 'vehicles':
      children = <VehicleList customerId={customerID} />;
      break;
    case 'vehicle':
      children = (
        <VehicleDetails
          vehicleId={String(id)}
          onSuccess={handleNavigateToCustomerDetails}
        />
      );
      break;
    case 'additionalAccount':
      children = <AdditionalAccounts customerId={customerID} />;
      break;
    case 'obus':
      children = <ObuList customerId={customerID} />;
      break;
    case 'obu':
      children = (
        <ObuDetails
          obuId={String(id)}
          onSuccess={handleNavigateToCustomerDetails}
        />
      );
      break;
    case 'addresses':
      children = <AddressList customerId={customerID} />;
      break;
    case 'contracts':
      children = <ContractList customerId={customerID} />;
      break;
    case 'contract':
      children = (
        <ContractDetails
          contractID={String(id)}
          onSuccess={handleNavigateToCustomerDetails}
        />
      );
      break;
    case 'contacts':
      children = <ContactList customerId={customerID} />;
      break;
    case 'cards':
      children = <CardList customerId={customerID} />;
      break;
    case 'card':
      children = (
        <CardDetails
          cardID={String(id)}
          customerID={customerID}
          onSuccess={handleNavigateToCustomerDetails}
        />
      );
      break;
    default:
      children = null;
  }

  return (
    <div className="bg-shellExtraPaleGrey2 flex flex-col flex-grow overflow-auto h-full">
      {children}
    </div>
  );
};

export default CustomerDetails;
