import React from 'react';
import { Text } from '@sede-x/shell-ds-react-framework';
import { useFormikContext } from 'formik';
import { SaveOBUDetailsDto } from './types';

const Summary: React.FC = () => {
  const { values } = useFormikContext<SaveOBUDetailsDto>();
  const OBUDetails = values.selectedOBUs.map((
    { vehicleId, obuNumber }) => ({ vehicleId, obuNumber }));

  return (
    <div
      className="bg-shellExtraPaleGrey2"
      data-testid="obu-order-summary">
      <div style={{ display: 'flex' }}>
        <Text className='w-4/12 pl-20 pt-10'>Action:</Text>
        <Text className='pt-10'>KMaster Order</Text>
      </div>
      <div style={{ display: 'flex' }}>
        <Text className='w-4/12 pl-20 pt-2'>Contact name:</Text>
        <Text className='pt-2'>{values.contactName}</Text>
      </div>
      <div style={{ display: 'flex' }}>
        <Text className='w-4/12 pl-20 pt-2'>Contact Surname:</Text>
        <Text className='pt-2'>{values.contactSurname}</Text>
      </div>
      <div style={{ display: 'flex' }}>
        <Text className='w-4/12 pl-20 pt-2'>Contact Phone:</Text>
        <Text className='pt-2'>{values.contactPhone}</Text>
      </div>
      <div style={{ display: 'flex' }}>
        <Text className='w-4/12 pl-20 pt-2'>Contact email address:</Text>
        <Text className='pt-2'>{values.contactEmail}</Text>
      </div>
      <div style={{ display: 'flex' }}>
        <Text className='w-4/12 pl-20 pt-2'>Language:</Text>
        <Text className='pt-2'>{values.a2}</Text>
      </div>
      <div style={{ display: 'flex' }}>
        <Text className='w-4/12 pl-20 pt-2'>Order Type:</Text>
        <Text className='pt-2'>{values.OBUType}</Text>
      </div>
      {OBUDetails.map((data) => (
        <div key={data.obuNumber} className="pt-4 pb-5">
          <div style={{ display: 'flex' }}>
            <Text bold className='w-4/12 pl-20 pt-1'>OBU Number:</Text>
            <Text bold className='pt-1'>{data.obuNumber}</Text>
          </div>
          <div style={{ display: 'flex' }}>
            <Text className='w-4/12 pl-20 pt-1'>Vehicle Registration Number:</Text>
            <Text className='pt-1'>{data.vehicleId}</Text>
          </div>
          <div style={{ display: 'flex' }}>
            <Text className='w-4/12 pl-20 pt-1'>Registered Services:</Text>
            <Text className='pt-1'>KMaster Business</Text>
          </div>
        </div>
      )
      )}
    </div>
  )
};
export default Summary;
