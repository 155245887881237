import {
  Alignments,
  Modal,
  Sizes,
  Text,
  Variants
} from '@sede-x/shell-ds-react-framework';
import {
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikValues
} from 'formik';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { customerInstance } from 'api';
import { errorHelper } from 'utils/helpers/errorHelper';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { queryClient } from 'react-query';
import OBUSelection from '../OBUTollServiceModification/components/OBUSelection';
import { ServiceRef } from '../OBUTollServiceModification/components/types';
import ReturnedObus from './components/ReturnedObus';
import AddressesSelection from './components/AddressesSelection';
import ServicesSelection from '../OBUTollServiceModification/components/ServicesSelection';
import { ObuActionsTelepassTollServiceReplacementDto } from './components/types';
import DocumentSelection from './components/DocumentSelection';
import Summary from './components/Summary';

const TOTAL_STEPS = 6;
const STEP_3 = 3;
const STEP_4 = 4;
const STEP_5 = 5;
const STEP_6 = 6;

const StyledModal = styled(Modal)`
  .shell-modal-container-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
  }
  .shell-modal-container-content {
    height: 80vh;
  }
`;

interface OBUTollServiceModificationProps {
  CustomerId: string;
  handleOnCloseModal: () => void;
  customerName: string;
}

const getTitles = (step: number) => {
  switch (step) {
    case 1:
      return 'Obus';
    case 2:
      return 'Returned Obus';
    case STEP_3:
      return 'Address';
    case 4:
      return 'Service Selection';

    case STEP_5:
      return 'Vehicle document selection';
    case TOTAL_STEPS:
      return 'Summary';
    default:
      return '';
  }
};

const ObuReplacement: React.FC<OBUTollServiceModificationProps> = ({
  CustomerId,
  handleOnCloseModal,
  customerName
}) => {
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const [step, setStep] = useState<number>(1);
  const serviceRef = useRef<ServiceRef>(null);

  const { errorDialog, successDialog } = useConfirmDialogs();

  const handleClose = () => {
    handleOnCloseModal();
    setStep(1);
  };

  const handleOnStepClick = (num: number, isBackClick = false) => {
    if (step === STEP_4 && !isBackClick) {
      if (!serviceRef.current?.validateService()) {
        return;
      }

      const servicesPayload: ObuActionsTelepassTollServiceReplacementDto[] =
        formRef.current?.values.servicesPayload;

      // check if bulgaria service is checked for any service
      const bulgariaService = servicesPayload?.find(
        (service) => service.bulgaria
      );

      if (bulgariaService) {
        setStep(num);
        return;
      }
      setStep(STEP_6);
      return;
    }

    if (step === STEP_6 && isBackClick) {
      const bulgariaService = formRef.current?.values.servicesPayload?.find(
        (service: ObuActionsTelepassTollServiceReplacementDto) =>
          service.bulgaria
      );

      if (!bulgariaService) {
        setStep(STEP_4);
        return;
      }
    }
    setStep(num);
  };

  const renderTitle = () => (
    <div className="flex flex-col space-y-4 pl-2">
      <Text size="small">Obu Replacement</Text>
      <Text prominence="strong" bold size="medium">
        {getTitles(step)}
      </Text>
      <Text size="small" className="text-gray-500">
        {customerName}
      </Text>
    </div>
  );

  const nextDisabled = (values: FormikValues) => {
    if (step === 1) {
      return !values.selectedOBUs;
    }

    if (step === STEP_3) {
      return !values.contact || !values.obuType;
    }
    return false;
  };

  const handleOnClickBack = (stepNum: number) => {
    switch (stepNum) {
      case 2:
        formRef.current?.setFieldValue('selectedOBUs', undefined);
        formRef.current?.setFieldValue('returnedObus', undefined);
        break;
      case STEP_3:
        formRef.current?.setFieldValue('contact', undefined);
        formRef.current?.setFieldValue('obuType', undefined);
        formRef.current?.setErrors({
          obuType: []
        });
        formRef.current?.setTouched({
          obuType: false
        });
        break;
      case 4:
        formRef.current?.setFieldValue('contact', undefined);
        formRef.current?.setFieldValue('obuType', undefined);
        formRef.current?.setErrors({
          obuType: []
        });
        formRef.current?.setTouched({
          obuType: false
        });
        formRef.current?.setFieldValue('servicesPayload', undefined);
        formRef.current?.setFieldValue('services', undefined);
        break;

      default:
        break;
    }
  };

  const handleSave = (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const { setSubmitting } = formikHelpers;
    const endpoint = 'obu/actions/telepass/save-obu-replacement-details';

    const payload = {
      CustomerId,
      contactId: values.contact?.contactId,
      obuReplacementOBUDetails: values.servicesPayload
    };

    customerInstance
      .post(endpoint, payload)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['obu-replacement-services']
        });
        queryClient.invalidateQueries({
          queryKey: ['obu-replacement-vehicle-documents']
        });
        queryClient.invalidateQueries({
          queryKey: ['obu-telepass-obuList']
        });
        successDialog('', 'Request has been successfully saved');

        handleOnCloseModal();
        setStep(1);
      })
      .catch((error) => {
        const message = 'Failed to save the request.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<FormikValues> = {};

    if (!values.obuType) {
      errors.obuType = 'Please select this mandatory field';
    }
    return errors;
  };

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={handleSave}
      innerRef={formRef}
      validate={validate}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <StyledModal
          title={renderTitle()}
          open
          width="90%"
          size={Sizes.Small}
          maskClosable={false}
          mask
          onClose={handleClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          actions={[
            {
              label: 'CANCEL',
              action: () => {
                handleOnCloseModal();
              },
              props: {
                variant: Variants.Outlined
              }
            },
            {
              label: 'BACK',
              action: () => {
                handleOnClickBack(step);
                handleOnStepClick(step - 1, true);
              },
              props: {
                variant: Variants.Outlined,
                hidden: step === 1,
                style: {
                  display: step === 1 ? 'none' : ''
                }
              }
            },
            {
              label: 'NEXT',
              action: () => {
                handleOnStepClick(step + 1);
              },
              props: {
                disabled: nextDisabled(values),
                hidden: step === TOTAL_STEPS,
                style: {
                  display: step === TOTAL_STEPS ? 'none' : ''
                }
              }
            },
            {
              label: 'FINISH',
              action: () => {
                handleSubmit();
              },
              props: {
                disabled: isSubmitting,
                type: 'submit',
                hidden: step !== TOTAL_STEPS,
                style: {
                  display: step !== TOTAL_STEPS ? 'none' : ''
                },
                'aria-hidden': step !== TOTAL_STEPS
              }
            }
          ]}
        >
          <div className=" flex  flex-col flex-grow overflow-auto h-full ">
            {step === 1 && <OBUSelection customerId={CustomerId} />}
            {step === 2 && <ReturnedObus />}
            {step === 3 && <AddressesSelection customerId={CustomerId} />}
            {step === 4 && (
              <ServicesSelection ref={serviceRef} customerId={CustomerId} />
            )}
            {step === 5 && <DocumentSelection />}
            {step === 6 && <Summary />}
          </div>
        </StyledModal>
      )}
    </Formik>
  );
};

export default ObuReplacement;
