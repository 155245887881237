import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { FormikValues, useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import { useState, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import { ObuPollDto } from './types';

const DEFAULT_PAGE_SIZE = 20;

async function fetchCustomerObuPools(
  customerId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post('obu/actions/t4e/get-obu-obu-pool-model', {
    customerId,
    pageNumber,
    pageSize
  });
}

interface OBUProductProps {
  customerId: string;
}

const OBUProduct: React.FC<OBUProductProps> = ({ customerId }) => {
  const { setFieldValue } = useFormikContext<FormikValues>();
  const [selectedRows, setSelectedRows] = useState<ObuPollDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'get-obu-obuTransfer-pool-model',
      customerId,
      pageSize,
      pageNumber
    ],
    queryFn: () =>
      fetchCustomerObuPools(customerId, pageNumber, pageSize).then(
        (res) => res.data
      ),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: 'always',
    keepPreviousData: true
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  useEffect(() => {
    if (selectedRows.length) {
      const row = selectedRows[0];
      setFieldValue('customerId', customerId);
      setFieldValue('obuSerialNumber', row.serialNo);
      setFieldValue('obuPoolId', row.id);
    }
  }, [selectedRows]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="obu-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <Table<ObuPollDto>
          data={data?.data ?? []}
          columns={
            getOverviewColumns(
              'OBUProductPersonalize'
            ) as ColumnDef<ObuPollDto>[]
          }
          columnSelection={false}
          exportEnabled={false}
          enableMultiRowSelection={false}
          onSelectedRowsChange={setSelectedRows}
          resetSelectedRowsOnPageChange={false}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
          getRowId={(row) => row.id}
        />
      </QueryError>
    </div>
  );
};

export default OBUProduct;
