import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import { useState, useEffect } from 'react';
import { customerEndPoints } from 'api/apiEndpoints';
import { ColumnDef } from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import { SaveOBUDetailsDto, VehicleDto } from './types';

const DEFAULT_PAGE_SIZE = 20;
interface OBUSelectionProps {
  customerId?: string;
}

const OBUSelection: React.FC<OBUSelectionProps> = ({ customerId }) => {
  const { values, setFieldValue } = useFormikContext<SaveOBUDetailsDto>();
  const [selectedRows, setSelectedRows] = useState<VehicleDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const obuTypeId = values?.obuTypeId;

  const { data, isLoading, isError } = useQuery(
    ['get-kmaster-vehicles', customerId, obuTypeId, pageSize, pageNumber],
    async () => {
      const result = await customerInstance.post(
        customerEndPoints.kmastervehicles,
        {
          pageNumber,
          pageSize,
          customerId,
          obuTypeId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return result.data;
    },
    {
      staleTime: STALE_TIME,
      refetchOnWindowFocus: 'always'
    }
  );

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  useEffect(() => {
    if (selectedRows.length) {
      const kmasterDetails = [];
      selectedRows.map((val) => {
        val.isK01Checked = true;
        val.OBUType = values.OBUType;
        return val;
      });
      setFieldValue('selectedOBUs', selectedRows);

      for (const details of selectedRows) {
        kmasterDetails.push({
          obuId: details.obuId,
          vehicleId: details.vehicleId,
          pan: details.cardNumber,
          obuNumber: details.obuNumber,
          obuFullNumber: details.obuFullNumber,
          obuTypeSTV: details.obuTypeSTV,
          contractId: values.contractId,
          contactId: values.contactId,
          obuTypeId: values.obuTypeId,
          serviceList: [
            {
              isK01Checked: true
            }
          ]
        });
        setFieldValue('OBUKmasterDetails', kmasterDetails);
      }
    }
    else {
      setFieldValue('selectedOBUs', undefined);
      setFieldValue('OBUKmasterDetails', undefined);
    }
  }, [selectedRows]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="obu-selection-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <Table<VehicleDto>
          data={data?.data ?? []}
          columns={
            getOverviewColumns('OBUSelection') as ColumnDef<VehicleDto>[]
          }
          columnSelection={false}
          exportEnabled={false}
          enableMultiRowSelection
          onSelectedRowsChange={setSelectedRows}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
        />
      </QueryError>
    </div>
  );
};

export default OBUSelection;
