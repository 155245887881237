import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { FormikValues, useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import {
  Column,
  ColumnDef,
  createColumnHelper,
  Row
} from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import { Checkbox, Flexbox, Tooltip } from '@sede-x/shell-ds-react-framework';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import _ from 'lodash';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import {
  ObuActionsTelepassTollServiceModificationsDto,
  OBUActionsTelepassTollServiceModificationServiceDto,
  ObuListDto,
  ServiceRef,
  ServicesDto,
  VehicleDto,
  VehicleWeightMap,
  VehicleWeightModel
} from './types';
import { VehicleCountryValidator } from './VehicleCountryValidator';
import { VehicleValidator } from './VehicleValidator';
import {
  ObuActionsTelepassTollServiceReplacementDto,
  ReturnedObuDto
} from '../../OBUReplacement/components/types';

const SLICE_NUMBER = -4;
const VALIDATION_ERROR_TITLE = 'Validation Error';

const defaultColoumns = [
  {
    header: 'OBU Type',
    id: 'obuType',
    accessorKey: 'obuType'
  },
  {
    header: 'Vehicle Registration',
    id: 'carRegistration',
    accessorKey: 'carRegistration'
  },
  {
    header: 'Telepass OBU Number',
    id: 'telepassObuNumber',
    accessorKey: 'telepassObuNumber'
  },

  {
    header: 'Country',
    id: 'country',
    accessorKey: 'country'
  }
];

async function fetchCustomerObus(Obus: ObuListDto[], obuTypeId?: string) {
  const endpoint = obuTypeId
    ? 'obu/actions/telepass/get-obu-replacement-services'
    : 'obu/actions/telepass/get-toll-service-modification-services';
  return customerInstance.post(endpoint, {
    Obus,
    obuTypeId
  });
}

async function fetchCustomerVehicleWeights(customerId: string) {
  return customerInstance.post('obu/actions/telepass/get-vehicle-weights', {
    customerId
  });
}

async function fetchCustomerVehicles(customerId: string) {
  return customerInstance.post('obu/actions/telepass/get-vehicles', {
    customerId
  });
}

const ItalyRuleTollTipText = 'Italy can not be activated individually.';
const ItalyDeactivationRuleTollTipText = 'Italy can not be deactivated.';
const PortugalRuleTollTipText = 'Portugal can not be activated without Spain.';

const columnHelper = createColumnHelper<ServicesDto>();

const newFieldMapping: Record<string, string> = {
  italyOrig: 'italyNew',
  franceOrig: 'franceNew',
  spainOrig: 'spainNew',
  portugalOrig: 'portugalNew',
  polandOrig: 'polandNew',
  polandKasOrig: 'polandKasNew',
  austriaOrig: 'austriaNew',
  germanyOrig: 'germanyNew',
  belgiumOrig: 'belgiumNew',
  scandinaviaOrig: 'scandinaviaNew',
  switzerlandOrig: 'switzerlandNew',
  bulgariaOrig: 'bulgariaNew',
  polandA1Orig: 'polandA1New',
  croatiaOrig: 'croatiaNew',
  sloveniaOrig: 'sloveniaNew',
  slovakiaOrig: 'slovakiaNew'
};

export const labelMapping: Record<string, string> = {
  italyOrig: 'Italy',
  franceOrig: 'France',
  spainOrig: 'Spain',
  portugalOrig: 'Portugal',
  polandOrig: 'A4 Poland',
  polandKasOrig: 'KAS Poland',
  austriaOrig: 'Austria',
  germanyOrig: 'Germany',
  belgiumOrig: 'Belgium',
  scandinaviaOrig: 'Scandinavia',
  switzerlandOrig: 'Switzerland',
  bulgariaOrig: 'Bulgaria',
  polandA1Orig: 'A1 Poland',
  croatiaOrig: 'Croatia',
  sloveniaOrig: 'Slovenia',
  slovakiaOrig: 'Slovakia'
};

const ServiceCodeMapping: Record<string, string> = {
  italyOrig: '05',
  franceOrig: '07',
  spainOrig: '09',
  portugalOrig: '11',
  polandOrig: '14',
  polandKasOrig: '67',
  austriaOrig: '13',
  germanyOrig: '15',
  belgiumOrig: '17',
  scandinaviaOrig: '30',
  switzerlandOrig: '53',
  bulgariaOrig: '61',
  polandA1Orig: '76',
  croatiaOrig: '77',
  sloveniaOrig: '78',
  slovakiaOrig: '83'
};

interface RenderColumnProps {
  row: Row<ServicesDto>;
  column: Column<ServicesDto>;
  country: string;
  onChangeCheckbox: (
    rowIndex: number,
    columnId: string,
    value: unknown
  ) => void;
}

const RenderColumn: React.FC<RenderColumnProps> = ({
  row,
  column,
  country,
  onChangeCheckbox
}) => (
  <Tooltip
    overlay={
      row.original[
        `notAvailableReason${
          country.charAt(0).toUpperCase() + country.slice(1, SLICE_NUMBER)
        }` as keyof ServicesDto
      ]
    }
    className={
      row.original[
        `is${
          country.charAt(0).toUpperCase() + country.slice(1, SLICE_NUMBER)
        }Available` as keyof ServicesDto
      ]
        ? 'hidden'
        : ''
    }
    trigger={
      <Flexbox gap="12px" alignItems="center" justifyContent="center">
        <Checkbox
          checked={row.original[country as keyof ServicesDto] as boolean}
          onChange={(e) =>
            onChangeCheckbox(row.index, column.id, e.target.checked)
          }
          data-testid={`${column.id}-${row.index}`}
          disabled={
            !row.original[
              `is${
                country.charAt(0).toUpperCase() + country.slice(1, -4)
              }Available` as keyof ServicesDto
            ]
          }
        />
      </Flexbox>
    }
  />
);

const validateVehicleWeightAndCategory = (
  services: ServicesDto[],
  vehicleWeightModels: VehicleWeightMap,
  vehiclesData: VehicleDto[]
) => {
  const vehiclesToCheck: Record<string, ServicesDto> = {};
  const vehicleCheckCategories: Record<string, ServicesDto> = {};

  services.forEach((item) => {
    if (item.austriaOrig || item.germanyOrig) {
      vehiclesToCheck[item.vehicleId] = item;
    }

    const selectedCountries = [
      'polandA1Orig',
      'croatiaOrig',
      'sloveniaOrig',
      'slovakiaOrig',
      'switzerlandOrig',
      'belgiumOrig',
      'germanyOrig'
    ];

    selectedCountries.some((country) => {
      if (item[country as keyof ServicesDto]) {
        vehicleCheckCategories[item.vehicleId] = item;
        return true;
      }
      return false;
    });
  });

  const validationResult: string[] = [];

  Object.keys(vehiclesToCheck).forEach((vehicleId) => {
    if (vehiclesToCheck[vehicleId].austriaOrig) {
      const resultAustralia = VehicleCountryValidator.ValidateForAustria(
        vehicleWeightModels[vehicleId]
      );
      resultAustralia && validationResult.push(resultAustralia);

      validationResult.push(
        ...VehicleValidator.ValidateVehicleForOBUCapability(
          vehicleWeightModels[vehicleId],
          'Austria'
        )
      );
    }

    if (vehiclesToCheck[vehicleId].germanyOrig) {
      validationResult.push(
        ...VehicleValidator.ValidateVehicleForOBUCapability(
          vehicleWeightModels[vehicleId],
          'Germany'
        )
      );
    }
  });

  ValidateCategory(vehiclesData, vehicleCheckCategories, validationResult);

  return validationResult;
};

const ValidateCategory = (
  vehiclesData: VehicleDto[],
  vehicleCheckCategories: Record<string, ServicesDto>,
  validationResult: string[]
) => {
  const filterdVehicleData = vehiclesData?.filter((vehicle: VehicleDto) =>
    Object.keys(vehicleCheckCategories).includes(vehicle.vehicleId)
  );

  const vehicleCategoriesModelsToCheck: Record<string, VehicleDto> =
    filterdVehicleData?.reduce(
      (
        acc: Record<string, VehicleDto>,
        item: VehicleDto
      ): Record<string, VehicleDto> => {
        acc[item.vehicleId] = item;
        return acc;
      },
      {}
    );

  Object.keys(vehicleCheckCategories).forEach((vehicleId) => {
    if (vehicleCategoriesModelsToCheck) {
      const vehicle = vehicleCategoriesModelsToCheck[vehicleId];
      if (vehicleCheckCategories[vehicleId].polandA1Orig) {
        const resultPolandA1 =
          VehicleCountryValidator.ValidateForPolandA1(vehicle);
        resultPolandA1 && validationResult.push(resultPolandA1);
      }

      if (vehicleCheckCategories[vehicleId].sloveniaOrig) {
        const resultSlovenia =
          VehicleCountryValidator.ValidateForSlovenia(vehicle);
        resultSlovenia && validationResult.push(resultSlovenia);
      }

      if (vehicleCheckCategories[vehicleId].slovakiaOrig) {
        const resultSlovakia =
          VehicleCountryValidator.ValidateForSlovakia(vehicle);
        resultSlovakia && validationResult.push(resultSlovakia);
      }

      if (vehicleCheckCategories[vehicleId].belgiumOrig) {
        const resultBelgium =
          VehicleCountryValidator.ValidateForBelgium(vehicle);
        resultBelgium && validationResult.push(resultBelgium);
      }

      VehicleCategoryCheckForGermanyAndSwitzerland(
        vehicleId,
        vehicle,
        vehicleCheckCategories,
        validationResult
      );
    }
  });
};

const VehicleCategoryCheckForGermanyAndSwitzerland = (
  vehicleId: string,
  vehicle: VehicleDto,
  vehicleCheckCategories: Record<string, ServicesDto>,
  validationResult: string[]
) => {
  if (vehicleCheckCategories[vehicleId].germanyOrig) {
    const resultGermany = VehicleCountryValidator.ValidateForGermany(vehicle);
    resultGermany && validationResult.push(resultGermany);
  }

  if (vehicleCheckCategories[vehicleId].switzerlandOrig) {
    const resultSwitzerland =
      VehicleCountryValidator.ValidateForSwitzerland(vehicle);
    resultSwitzerland && validationResult.push(resultSwitzerland);
  }
};

interface ServicesSelectionProps {
  customerId: string;
}

function SetPortugalAvailabilityRule(service: ServicesDto) {
  if (
    service.obuTypeId.toUpperCase() !== '62AB337D-AEB9-E911-8156-000D3A245DC3'
  ) {
    return;
  }

  service.isPortugalAvailable = service.spainOrig;

  if (!service.isPortugalAvailable) {
    service.portugalNew = false;
    service.portugalOrig = false;
    service.notAvailableReasonPortugal += PortugalRuleTollTipText;
  } else {
    service.notAvailableReasonPortugal =
      service.notAvailableReasonPortugal.replace(PortugalRuleTollTipText, '');
  }
}

function SetItalyAvailabilityRule(service: ServicesDto, italyOrig: boolean) {
  if (
    service.obuTypeId.toUpperCase() !== '62AB337D-AEB9-E911-8156-000D3A245DC3'
  ) {
    return;
  }

  if (italyOrig === true) {
    service.isItalyAvailable = false;
    if (
      !String(service.notAvailableReasonItaly).includes(
        ItalyDeactivationRuleTollTipText
      )
    ) {
      service.notAvailableReasonItaly += ItalyDeactivationRuleTollTipText;
    }
    return;
  }

  const newFieldsArray = Object.values(newFieldMapping);
  newFieldsArray.shift();
  service.isItalyAvailable = newFieldsArray.some(
    (country) => service[country as keyof ServicesDto]
  );

  if (!service.isItalyAvailable) {
    service.italyNew = false;
    service.notAvailableReasonItaly += ItalyRuleTollTipText;
  } else {
    service.notAvailableReasonItaly = service.notAvailableReasonItaly.replace(
      ItalyRuleTollTipText,
      ''
    );
  }
}

const CheckAvailabilityRule = (
  columnId: string,
  modifiedRow: ServicesDto,
  selectedService?: ServicesDto
) => {
  if (columnId === 'spainOrig') {
    SetPortugalAvailabilityRule(modifiedRow);
  }

  if (columnId !== 'italyOrig' && selectedService) {
    SetItalyAvailabilityRule(modifiedRow, selectedService.italyOrig);
  }
};

const constructServicePayload = (
  services: ServicesDto[],
  originalServices: ServicesDto[],
  obuType: string,
  returnedObusMap: Record<string, ReturnedObuDto>
) => {
  const changedServices = services.filter((service) => {
    const originalService = originalServices.find(
      (origService: ServicesDto) => origService.obuId === service.obuId
    );
    return !_.isEqual(service, originalService);
  });

  //  create new array with changed services adding new fields

  let newServices:
    | ObuActionsTelepassTollServiceModificationsDto[]
    | ObuActionsTelepassTollServiceReplacementDto[];

  if (!obuType) {
    newServices = changedServices.map((service) => {
      const newService: ObuActionsTelepassTollServiceModificationsDto = {
        ObuId: service.obuId,
        ObuTypeId: service.obuTypeId,
        VehicleId: service.vehicleId,
        TelepassObuNumber: service.telepassObuNumber,
        OBUActionsTelepassTollServiceModificationServices: []
      };

      const originalService = originalServices.find(
        (origService: ServicesDto) => origService.obuId === service.obuId
      );

      Object.keys(newFieldMapping).forEach((country) => {
        if (
          originalService &&
          service[country as keyof ServicesDto] !==
            originalService[country as keyof ServicesDto]
        ) {
          const modifiedService: OBUActionsTelepassTollServiceModificationServiceDto =
            {
              operation: service[country as keyof ServicesDto] ? 'A' : 'D',
              ServiceCode: ServiceCodeMapping[country]
            };

          newService.OBUActionsTelepassTollServiceModificationServices?.push(
            modifiedService
          );
        }
      });

      return newService;
    });
  } else {
    newServices = services.map((service) => {
      const newService: ObuActionsTelepassTollServiceReplacementDto = {
        obuId: service.obuId,
        obuType: service.obuType,
        VehicleId: service.vehicleId,

        carRegistration: service.carRegistration,
        telepassObuNumber: service.telepassObuNumber,
        returnStatus: returnedObusMap[service.obuId]?.returnStatus || 0,
        italy: service.italyOrig,
        france: service.franceOrig,
        spain: service.spainOrig,
        portugal: service.portugalOrig,
        poland: service.polandOrig,
        polandKAS: service.polandKasOrig,
        austria: service.austriaOrig,
        germany: service.germanyOrig,
        belgium: service.belgiumOrig,
        scandinavia: service.scandinaviaOrig,
        switzerland: service.switzerlandOrig,
        bulgaria: service.bulgariaOrig,
        polandA1: service.polandA1Orig,
        croatia: service.croatiaOrig,
        slovenia: service.sloveniaOrig,
        slovakia: service.slovakiaOrig,
        documents: []
      };

      return newService;
    });
  }

  return newServices;
};

const getQueryKeys = (obuType: string, obuIds: string[]) => {
  const queryKeys = [
    obuType
      ? 'obu-replacement-services'
      : 'obu-ServiceModification-ServiceList',
    ...obuIds
  ];

  if (obuType) {
    queryKeys.push(obuType);
  }

  return queryKeys;
};

const ServicesSelection = forwardRef<ServiceRef, ServicesSelectionProps>(
  ({ customerId }, ref) => {
    const { values, setFieldValue } = useFormikContext<FormikValues>();
    const [services, setServices] = useState<ServicesDto[]>([]);
    const [vehicleWeightModels, setVehicleWeightModels] =
      useState<VehicleWeightMap>({});
    const [returnedObusMap, setReturnedObusMap] = useState<
      Record<string, ReturnedObuDto>
    >({});

    const { infoDialog } = useConfirmDialogs();

    const selectedObuIds = values.selectedOBUs?.map(
      (obu: ObuListDto) => obu.obuId
    );

    const obuType = values?.obuType;

    const queryKeys = getQueryKeys(obuType, selectedObuIds);

    const { isError, data, isLoading } = useQuery({
      queryKey: queryKeys,
      queryFn: () =>
        fetchCustomerObus(values.selectedOBUs, obuType).then((res) => res.data),
      staleTime: STALE_TIME,
      enabled: !!values.selectedOBUs
    });

    const {
      data: vehicleWeightsData,
      isError: fetchVehicleWeightError,
      isLoading: VehicleWeightLoading
    } = useQuery({
      queryKey: ['obu-ServiceModification-vehicleWeights', customerId],
      queryFn: () =>
        fetchCustomerVehicleWeights(customerId).then((res) => res.data),
      staleTime: STALE_TIME,
      refetchOnWindowFocus: 'always'
    });

    const {
      data: vehiclesData,
      isError: fetchVehicleError,
      isLoading: VehicleLoading
    } = useQuery({
      queryKey: ['obu-ServiceModification-vehicles', customerId],
      queryFn: () => fetchCustomerVehicles(customerId).then((res) => res.data),
      staleTime: STALE_TIME,
      refetchOnWindowFocus: 'always'
    });

    useEffect(() => {
      if (values?.returnedObus) {
        setReturnedObusMap(
          values?.returnedObus.reduce(
            (acc: Record<string, ReturnedObuDto>, item: ReturnedObuDto) => {
              acc[item.obuId] = item;
              return acc;
            },
            {}
          )
        );
      }
    }, [values?.returnedObus]);

    useEffect(() => {
      if (vehicleWeightsData?.data) {
        setVehicleWeightModels(
          vehicleWeightsData.data.reduce(
            (
              acc: Record<string, VehicleWeightModel>,
              item: VehicleWeightModel
            ) => {
              acc[item.vehicleId] = item;
              return acc;
            },
            {}
          )
        );
      }
    }, [vehicleWeightsData]);

    useEffect(() => {
      if (data?.data) {
        setServices(data.data);
      }
    }, [data]);

    useImperativeHandle(ref, () => ({
      validateService() {
        if (isError || fetchVehicleWeightError || fetchVehicleError) {
          return false;
        }
        const missingServices = services.filter(
          (service) =>
            !values.servicesPayload.find(
              (
                originalService: ObuActionsTelepassTollServiceModificationsDto
              ) => originalService.ObuId === service.obuId
            )
        );
        const errorMessages = missingServices.map(
          (service) => `There is no change for OBU ${service.telepassObuNumber}`
        );

        if (missingServices.length && !obuType) {
          const result = errorMessages.map((msg: string) => `<li>${msg}</li>`);
          const message = `<ul>${result.join('')}</ul>`;
          infoDialog(VALIDATION_ERROR_TITLE, message);
          return false;
        }

        const errors: string[] = [];
        services.forEach((service) => {
          const hasSelectedService = Object.keys(ServiceCodeMapping).some(
            (country) => service[country as keyof ServicesDto]
          );
          if (!hasSelectedService) {
            errors.push(
              `There is no service selected for OBU ${service.telepassObuNumber}`
            );
          }
        });

        if (errors.length) {
          renderError(errors);
          return false;
        }

        errors.push(
          ...validateVehicleWeightAndCategory(
            services,
            vehicleWeightModels,
            vehiclesData?.data
          )
        );

        if (errors.length) {
          renderError(errors);
          return false;
        }

        return true;
      }
    }));

    function renderError(errors: string[]) {
      const result = errors.map((msg: string) => `<li>${msg}</li>`);
      const message = `<ul class="list-disc 
        list-outside p-2 m-2">${result.join('')}</ul>`;
      infoDialog(VALIDATION_ERROR_TITLE, message);
    }

    useEffect(() => {
      setFieldValue(
        'servicesPayload',
        constructServicePayload(services, data?.data, obuType, returnedObusMap)
      );
      setFieldValue('services', services);
    }, [services, data, returnedObusMap]);

    const onChangeCheckbox = (
      rowIndex: number,
      columnId: string,
      value: unknown
    ) => {
      setServices((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            const newField = newFieldMapping[columnId];
            let newFieldData = value;

            const columnField = columnId as keyof ServicesDto;
            let selectedService: ServicesDto | undefined;
            data?.data?.forEach((item: ServicesDto) => {
              if (item.obuId === row.obuId) {
                newFieldData =
                  item[columnField] !== value
                    ? value
                    : item[newField as keyof ServicesDto];
                selectedService = item;
              }
            });

            const modifiedRow = {
              ...old[rowIndex]!,
              [columnId]: value,
              [newField]: newFieldData
            };

            CheckAvailabilityRule(columnId, modifiedRow, selectedService);

            return modifiedRow;
          }
          return row;
        })
      );
    };

    const columns = React.useMemo<ColumnDef<ServicesDto>[]>(
      () => [
        ...defaultColoumns,
        ...Object.keys(newFieldMapping).map((country) =>
          columnHelper.display({
            id: country,
            header: labelMapping[country],
            size: 20,
            cell: ({ row, column }) =>
              RenderColumn({ row, column, country, onChangeCheckbox })
          })
        )
      ],
      [data]
    );

    return (
      <div
        className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
        data-testid="obu-selection-list"
      >
        <QueryError
          isLoading={isLoading || VehicleWeightLoading || VehicleLoading}
          isError={isError || fetchVehicleWeightError || fetchVehicleError}
        >
          <Table<ServicesDto>
            data={services}
            columns={columns}
            columnSelection={false}
            exportEnabled={false}
            stickyColumns={['obuType']}
          />
        </QueryError>
      </div>
    );
  }
);

export default ServicesSelection;
