import { ItemType, MenuInfo } from 'rc-menu/lib/interface';
import React, { useEffect, useState } from 'react';
import { createMap } from 'utils/helpers';
import { SelectedSideMenu } from '../components/Customer/types';
import { StyledMenu } from './style';

const FULL_WIDTH = 230;

interface SideMenuProps {
  menuList: ItemType[];
  selectedMenu: string;
  setMenu: (menu: SelectedSideMenu) => void;
}

const SideMenu: React.FC<SideMenuProps> = ({
  menuList,
  selectedMenu,
  setMenu
}) => {
  const [menuMap, setMenuMap] = useState<{ [key: string]: unknown }>({});

  useEffect(() => {
    setMenuMap(createMap(menuList, 'key'));
  }, [menuList]);

  const handleChange = (menu: MenuInfo) => {
    setMenu({
      ...menu,
      ...(menuMap[menu.key] as SelectedSideMenu)
    });
  };

  return (
    <div style={{ width: FULL_WIDTH }} className="h-5/6">
      <StyledMenu
        items={menuList}
        selectedKeys={[selectedMenu]}
        onClick={handleChange}
        size="large"
      />
    </div>
  );
};

export default SideMenu;
