import { Icons, Text } from '@sede-x/shell-ds-react-framework';
import { ColumnDef, createColumnHelper, Row } from '@tanstack/react-table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import lodash from 'lodash';
import { ItemType } from 'rc-menu/lib/interface';
import { axiosInstance } from 'api';
import {
  DeleteTransactionPayload,
  ExportAllPayload,
  Roles,
  BlockContractDeregisteredPayload,
  AddExceptionPayload
} from './types';

const emptyValue = '';
const DECIMAL_ROUNDOFF_TWO = 2;
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export const renderDate = (
  value: string,
  format: string = 'DD/MM/YYYY HH:mm',
  parseFormat?: string
) =>
  value ? (
    <Text size="small">{formatAndParseDate(value, format, parseFormat)}</Text>
  ) : (
    <Text size="small">{emptyValue}</Text>
  );

export const formatAndParseDate = (
  date: string | number | Date,
  format: string,
  parseFormat?: string
) => {
  if (parseFormat) {
    return dayjs(date, parseFormat).format(format);
  }
  return dayjs(date).format(format);
};

export const formatGMTtoLocalDate = (
  date: string | number | Date,
  format: string
) => {
  const gmtTimeObj = dayjs.utc(date);
  const localTimeZone = dayjs.tz.guess();
  return gmtTimeObj.tz(localTimeZone).format(format);
};
type TableData = string | number | boolean | Date | null | undefined;
type ColumnHelperType = {
  [k: string]: TableData;
};

type ColumnType = {
  field: string;
  label: string;
  type?: string;
  decimalRoundOff?: number;
  outputDatePattern?: string;
  inputDateFormat?: string;
};

const renderToggle = (value: string) => {
  if (value === 'true') {
    return (
      <span className="flex justify-center">
        <Icons.Check
          width={20}
          height={20}
          data-testid="toggle-check"
          fill="#343434"
        />
      </span>
    );
  }
  return (
    <span className="flex justify-center">
      <Icons.CrossCircle fill="#DD1D21" width={20} height={20} />
    </span>
  );
};

const checkAndRenderDate = (
  value: unknown,
  outputPattern?: string,
  inputPattern?: string
) => {
  if (value && String(value).trim()) {
    return renderDate(
      value as string,
      outputPattern ?? DEFAULT_DATE_FORMAT,
      inputPattern
    );
  }
  return emptyValue;
};

const DateSort = (valueA: string, valueB: string, data: ColumnType) => {
  if (!valueA && !valueB) {
    return 0;
  }
  if (!valueA) {
    return -1;
  }
  if (!valueB) {
    return 1;
  }
  const displayFormat = data.outputDatePattern ?? DEFAULT_DATE_FORMAT;
  const numA = dayjs(
    formatAndParseDate(valueA, displayFormat, data.inputDateFormat),
    displayFormat
  );
  const numB = dayjs(
    formatAndParseDate(valueB, displayFormat, data.inputDateFormat),
    displayFormat
  );

  if (numA.isBefore(numB)) {
    return -1;
  }

  return numA.isAfter(numB) ? 1 : 0;
};

const sortingFunction = <TData,>(data: ColumnType) => {
  if (data.type === 'date') {
    return (rowA: Row<TData>, rowB: Row<TData>, columnId: string) => {
      const valueA = String(rowA.getValue(columnId)).trim();
      const valueB = String(rowB.getValue(columnId)).trim();
      return DateSort(valueA, valueB, data);
    };
  }
  return 'auto';
};

export const columnBuilder = <TData,>(columnArray: ColumnType[]) => {
  const result: ColumnDef<TData>[] = [];
  const columnHelper = createColumnHelper<ColumnHelperType>();
  [...columnArray].forEach((data) =>
    result.push(
      columnHelper.accessor(data.field, {
        header: data.label,
        cell: (info) => {
          if (data.type === 'date') {
            return checkAndRenderDate(
              info.getValue(),
              data.outputDatePattern,
              data.inputDateFormat
            );
          }
          if (data.type === 'time') {
            return renderDate(info.getValue() as string, 'h:mm A');
          }
          if (data.type === 'decimal') {
            return (
              info.getValue() &&
              Number(info.getValue()).toFixed(
                data.decimalRoundOff ?? DECIMAL_ROUNDOFF_TWO
              )
            );
          }
          if (data.type === 'toggle') {
            const value = String(info.getValue());
            return renderToggle(value);
          }
          if (data.type === 'pre') {
            return <pre>{String(info.getValue())}</pre>;
          }

          return typeof info.getValue() === 'boolean'
            ? String(info.getValue())
            : info.getValue();
        },
        sortingFn: sortingFunction<ColumnHelperType>(data)
      }) as ColumnDef<TData>
    )
  );
  return result;
};

export const createMap = <TData,>(
  data: TData[],
  key: string,
  subField: string = 'children'
) => {
  function getMembers(member: { [subField: string]: TData[] }): unknown {
    if (!member[subField]?.length) {
      return member;
    }
    return [member, lodash.flatMapDeep(member[subField], getMembers)];
  }

  return lodash.keyBy(lodash.flatMapDeep(data, getMembers), key);
};

export function filterReportMenuBasedOnRoles(
  items: ItemType[],
  userRoles?: Roles
) {
  return [...items].filter((menuItem) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const item = menuItem as any;
    return userRoles?.tollPermissions.some((toll) => {
      if (item.key === toll.tollName) {
        item.children &&
          (item.children = [...item.children].filter(function f(o: unknown) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const subItem = o as any;
            return toll.tollReportPermissions.some((report) => {
              subItem.children =
                subItem.children && [...subItem.children].filter(f);
              return (
                subItem.reportName === report.reportName ||
                subItem.children?.length
              );
            });
          }));
        return item.children?.length || item;
      }
      return false;
    });
  });
}

export const getReportDetails = (
  reportName: string,
  tollId: string,
  userRoles: Roles
) =>
  userRoles.tollPermissions
    .find((toll) => toll.tollID === tollId)
    ?.tollReportPermissions.find((report) => report.reportName === reportName);

export function createExportAllRequest(
  endpoint: string,
  payload: ExportAllPayload
) {
  return axiosInstance.post(endpoint, payload);
}

export function deleteTransationErrorRequest(
  endpoint: string,
  payload: DeleteTransactionPayload
) {
  return axiosInstance.post(endpoint, payload);
}

export function blockRequest(
  endpoint: string,
  payload: BlockContractDeregisteredPayload
) {
  return axiosInstance.post(endpoint, payload);
}

export function addException(endpoint: string, payload: AddExceptionPayload) {
  return axiosInstance.post(endpoint, payload);
}

export function removerOrders(endpoint: string, payload: string[]) {
  return axiosInstance.post(endpoint, payload);
}

export const viewPdf = (base64Content: string) => {
  const blob = base64toBlob(base64Content);
  const url = URL.createObjectURL(blob);
  window.open(url);
};

const base64toBlob = (data: string) => {
  const bytes = atob(data);
  const { length } = bytes;
  const out = new Uint8Array(length);

  let i = 0;
  while (i < length) {
    out[i] = bytes.charCodeAt(i);
    i++;
  }

  return new Blob([out], { type: 'application/pdf' });
};
