import React, { ReactNode } from 'react';
import { Text } from '@sede-x/shell-ds-react-framework';
import { FormikValues, useFormikContext } from 'formik';

const BoldText = ({ children }: { children: ReactNode }) => (
  <span className="font-bold">{children}</span>
);

interface SummaryProps {
  customerNumber: string;
}

const Summary: React.FC<SummaryProps> = ({ customerNumber }) => {
  const { values } = useFormikContext<FormikValues>();

  return (
    <div className="flex flex-col grow overflow-y-auto p-5 bg-shellExtraPaleGrey2 gap-8">
      <div className="flex">
        <Text>
          OBU Serial Number <BoldText>{values?.obuSerialNumber}</BoldText> will
          be transfered
        </Text>
      </div>
      <div>
        <Text>
          From customer: <BoldText>{customerNumber}</BoldText>
        </Text>
        <Text>
          To customer: <BoldText>{values?.customerNumberTo}</BoldText>
        </Text>
      </div>
    </div>
  );
};
export default Summary;
