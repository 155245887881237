import QueryError from 'components/QueryError';
import { useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { ColumnDef } from '@tanstack/react-table';
import {
  getOverviewColumns,
} from 'components/Table/columns/overviewColumns';
import { SaveOBUDetailsDto, VehicleDto } from './types';

const ServiceOverview: React.FC = () => {
  const { values } = useFormikContext<SaveOBUDetailsDto>();

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="obu-list"
    >
      <QueryError>
         <Table<VehicleDto>
           data={values?.selectedOBUs || []}
          columns={getOverviewColumns('ServiceOverview') as ColumnDef<VehicleDto>[]}
          columnSelection={false}
          exportEnabled={false}
        />
      </QueryError>
    </div>
  );
};

export default ServiceOverview;
