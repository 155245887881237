import { ActionMenu } from 'components/ActionMenu/ActionMenu';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { STALE_TIME } from 'react-query';
import { fetchTollStatus } from 'components/TollInformation/TollStatus/TollStatus';
import { Toll } from 'components/TollInformation/TollStatus/types';
import OBUActions from './components/Telepass/OBUKmasterRegistrationDeregistration/OBUAction';
import OBUTollServiceModification from './components/Telepass/OBUTollServiceModification/OBUTollServiceModification';
import OrderAndPersonalizeObuActions from './components/T4E/T4EObuOrderActions/OrderAndPersonalizeObuActions';
import OrderAndNoPersonalizeObuActions from './components/T4E/T4EObuOrderActions/OrderAndNoPersonalizeObuActions';
import PersonalizeObuActions from './components/T4E/T4EPersonalizeObuActions/PersonalizeObuActions';
import DepersonalizeObuActions from './components/T4E/T4EDepersonalizeObuActions/DepersonalizeObuActions';
import DepersonalizeEtmOnlyObuActions from './components/T4E/T4EDepersonalizeObuActions/DepersonalizeEtmOnlyObuActions';
import { Customer } from '../Customer/types';
import ObuReplacement from './components/Telepass/OBUReplacement/ObuReplacement';
import CompleteObuTransfer from './components/T4E/CompleteObuTransfer/CompleteObuTransfer';
import T4EObuTransfer from './components/T4E/T4EObuTransfer/T4EObuTransfer';

interface ActionsProps {
  customer: Customer;
}

const Actions: React.FC<ActionsProps> = ({ customer }) => {
  const [selectedMenu, setSelectedMenu] = useState<string | null>();
  const [, setTollStatusMap] = useState<{ [key: string]: Toll }>({});

  const customerId = customer?.customerID as string;
  const customerName = customer?.name as string;

  const { data: tollStatusData } = useQuery({
    queryKey: ['toll-Status', customerId, 'customer'],
    queryFn: () => fetchTollStatus('customer', customerId),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    enabled: !!customerId
  });

  useEffect(() => {
    if (tollStatusData?.data) {
      const obj: { [key: string]: Toll } = {};
      tollStatusData?.data.forEach((tollObj: Toll) => {
        obj[tollObj.tollName.toLowerCase()] = tollObj;
      });
      setTollStatusMap(obj);
    }
  }, [tollStatusData]);

  const handleMenuClick = (menu: MenuInfo) => {
    const { key, keyPath } = menu;
    const tollIndex = 3;

    const _tollName = keyPath[keyPath.length - tollIndex];

    setSelectedMenu(key);
  };

  const handleOnCloseModal = () => {
    setSelectedMenu('T4E');
  };

  return (
    <div>
      <ActionMenu onMenuClick={handleMenuClick} />

      {selectedMenu === 'obuKmasterRegistrationDeregistration' && (
        <OBUActions customerId={customerId} setSelectedMenu={setSelectedMenu} />
      )}

      {selectedMenu === 'orderAndPersonalizeObu' && (
        <OrderAndPersonalizeObuActions
          CustomerId={customerId}
          customerName={customerName}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}

      {selectedMenu === 'orderObu' && (
        <OrderAndNoPersonalizeObuActions
          CustomerId={customerId}
          customerName={customerName}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}

      {selectedMenu === 'transferObu' && (
        <T4EObuTransfer
          CustomerId={customerId}
          customerName={customerName}
          customerNumber={customer.customerNo as string}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}

      {selectedMenu === 'personalizeObu' && (
        <PersonalizeObuActions
          CustomerId={customerId}
          customerName={customerName}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}

      {selectedMenu === 'depersonalizeObu' && (
        <DepersonalizeObuActions
          CustomerId={customerId}
          customerName={customerName}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}

      {selectedMenu === 'depersonalizeObuEtmOnly' && (
        <DepersonalizeEtmOnlyObuActions
          CustomerId={customerId}
          customerName={customerName}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}

      {selectedMenu === 'obuTollServiceModification' && (
        <OBUTollServiceModification
          CustomerId={customerId}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}

      {selectedMenu === 'obuReplacement' && (
        <ObuReplacement
          CustomerId={customerId}
          handleOnCloseModal={handleOnCloseModal}
          customerName={customer.name as string}
        />
      )}

      {selectedMenu === 'completeTransferObu' && (
        <CompleteObuTransfer
          CustomerId={customerId}
          customerName={customerName}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}
    </div>
  );
};

export default Actions;
