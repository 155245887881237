import { VehicleWeightModel, VehicleDto } from './types';

export class VehicleCountryValidator {
  static readonly WeightLimit: number = 3500;

  public static ValidateForAustria(vehicle: VehicleWeightModel): string {
    const weight = vehicle?.totalVehicleWeight;

    if (weight <= this.WeightLimit) {
      return `Vehicle ${vehicle.carRegistration} cannot be registered in Austria - vehicle weight needs to be higher than 3.5t!`;
    }
    return '';
  }

  public static ValidateForPolandA1(vehicle: VehicleDto) {
    if (vehicle.categoryName === 'M1' || vehicle.categoryName === 'N1') {
      return `Vehicle ${vehicle.licensePlate} cannot be registered for A1 Poland - vehicle category cannot be M1/N1!`;
    }

    return '';
  }

  public static ValidateForSlovenia(vehicle: VehicleDto): string {
    if (vehicle.categoryName === 'M1' || vehicle.categoryName === 'N1') {
      return `Vehicle ${vehicle.licensePlate} cannot be registered in Slovenia - vehicle category cannot be M1/N1!`;
    }

    return '';
  }

  public static ValidateForSlovakia(vehicle: VehicleDto): string {
    if (vehicle.categoryName === 'M1' || vehicle.categoryName === 'N1') {
      return `Vehicle ${vehicle.licensePlate} cannot be registered in Slovakia - vehicle category cannot be M1/N1!`;
    }

    return '';
  }

  public static ValidateForBelgium(vehicle: VehicleDto): string {
    if (
      vehicle.categoryName === 'M1' ||
      vehicle.categoryName === 'M2' ||
      vehicle.categoryName === 'M3'
    ) {
      return `Vehicle ${vehicle.licensePlate} is of category ${vehicle.categoryName} and Buses/Coaches are exempt from toll payment in Belgium.`;
    }

    return '';
  }

  public static ValidateForGermany(vehicle: VehicleDto): string {
    if (
      vehicle.categoryName === 'M1' ||
      vehicle.categoryName === 'M2' ||
      vehicle.categoryName === 'M3'
    ) {
      return `Vehicle ${vehicle.licensePlate} is of category ${vehicle.categoryName} and Buses/Coaches are exempt from toll payment in Germany.`;
    }

    return '';
  }

  public static ValidateForSwitzerland(vehicle: VehicleDto): string {
    if (
      vehicle.categoryName === 'M1' ||
      vehicle.categoryName === 'M2' ||
      vehicle.categoryName === 'M3'
    ) {
      return `Vehicle ${vehicle.licensePlate} is of category ${vehicle.categoryName} and Buses/Coaches are exempt from toll payment in Switzerland.`;
    }

    return '';
  }
}
