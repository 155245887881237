export const ErrorMessagesText = {
  VehicleValidator_PendingVehicle:
    'The vehicle is in a pending status and cannot be selected now.',
  ObuValidator_ObuInPendingState:
    'The OBU is in pending state and cannot be included into processing.',
  VehicleValidator_ObuAlreadyAssigned:
    'The vehicle has already an OBU assigned.',
  VehicleValidator_RegistrationNumber(registrationNumber: string) {
    return `The vehicle ${registrationNumber} must have Registration Number fulfilled.`;
  },
  VehicleValidator_Country(registrationNumber: string) {
    return `The vehicle ${registrationNumber} must have Country fulfilled.`;
  },
  VehicleValidator_EmissionClass(registrationNumber: string) {
    return `The vehicle ${registrationNumber} must have Emission Class fulfilled.`;
  },
  VehicleValidator_Axles(registrationNumber: string) {
    return `The vehicle ${registrationNumber} cannot have zero or negative number of axles.`;
  },
  VehicleValidator_GrossWeight:
    'The Weight F2 must be in range between 1 and 65535.',
  VehicleValidator_Category(registrationNumber: string) {
    return `The vehicle ${registrationNumber} must have Category fulfilled.`;
  },
  VehicleValidator_TotalWeight:
    'The Total weight (G3) must be in range between 0 and 65535 if present.',
  VehicleValidator_VIN(registrationNumber: string) {
    return `The vehicle ${registrationNumber} must have VIN fulfilled.`;
  },
  VehicleValidator_VIN_Format(registrationNumber: string) {
    return `The VIN of a vehicle ${registrationNumber} has incorrect format.`;
  },
  VehicleValidator_STVCardNumberMissing:
    'The vehicle does not have assigned card number.'
};
