import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { useFormikContext, Field } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import {
  getOverviewColumns,
} from 'components/Table/columns/overviewColumns';
import SelectField from 'components/FormCmponents/SelectField';
import { useState, useEffect } from 'react';
import { customerEndPoints } from 'api/apiEndpoints';
import { ColumnDef } from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import { SaveOBUDetailsDto, AddressSelectionDto } from './types';

const DEFAULT_PAGE_SIZE = 20;
interface AddressesSelectionProps {
  customerId?: string;
}

const AddressesSelection: React.FC<AddressesSelectionProps> = ({ customerId }) => {
  const { setFieldValue } = useFormikContext<SaveOBUDetailsDto>();
  const [selectedRows, setSelectedRows] = useState<AddressSelectionDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { data, isLoading, isError } = useQuery(
    ['get-kmaster-addresses', customerId, pageSize, pageNumber],
    async () => {
      const result = await customerInstance.post(
        customerEndPoints.kmasterAddresses,
        {
          pageNumber,
          pageSize,
          customerId,
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return result.data;
    },
    {
      staleTime: STALE_TIME,
      refetchOnWindowFocus: 'always'
    }
  );

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  useEffect(() => {
    if (selectedRows.length) {
      const row = selectedRows[0];
      setFieldValue('contactId', row.contactId);
      setFieldValue('contactName', row.firstName);
      setFieldValue('contactSurname', row.lastName);
    }
  }, [selectedRows]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="obu-addresses-selection-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <Table<AddressSelectionDto>
          data={data?.data ?? []}
          columns={getOverviewColumns('OBUAddressesSelection') as ColumnDef<AddressSelectionDto>[]}
          columnSelection={false}
          exportEnabled={false}
          enableMultiRowSelection={false}
          onSelectedRowsChange={setSelectedRows}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
        />
      <div className="w-1/3">
        <Field
          id="obuTypeId"
          name="obuTypeId"
          placeholder="Type here"
          label="Order Type"
          component={SelectField}
          dataEndPoint={customerEndPoints.kmasterObuTypes}
          dataField="obuTypeId"
          dataLabelField="obuTypeDescription"
          onOption
          mandatory
          isCustomerInstance
          onChange={(value: string) => {
            setFieldValue('OBUType', value === 'fae8bbc4-ee27-4f15-bae8-b29da7b71659' ? 'Register' : 'Deregister');
            setFieldValue('obuTypeId', value);
          }}
        />
      </div>
      </QueryError>
    </div>
  );
};

export default AddressesSelection;
