import { FormikErrors } from 'formik';
import { SaveOBUDetailsDto } from './types';

const ERROR_PHONE_NUMBER = 'Please select phone number';
const ERROR_EMAIL_ADDRESS = 'Please select email address';
const ERROR_NOTIFICATION_LANGUAGE = 'Please select notification Language';
const INVALID_EMAIL_ADDRESS = 'Please enter valid email address';

function isValidEmail(email: string): boolean {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

interface ErrorFields {
  contactPhone: string;
  contactEmail: string;
  a2: string;
}

export const validateDetails = (values: ErrorFields) => {
  const errors: FormikErrors<SaveOBUDetailsDto> = {};

  if (!values.contactPhone) {
    errors.contactPhone = ERROR_PHONE_NUMBER;
  }

  if (!values.contactEmail) {
    errors.contactEmail = ERROR_EMAIL_ADDRESS;
  }

  if (!values.a2) {
    errors.a2 = ERROR_NOTIFICATION_LANGUAGE;
  }

  if (values.contactEmail && !isValidEmail(values.contactEmail)) {
    errors.contactEmail = INVALID_EMAIL_ADDRESS;
  }

  return errors;
};
