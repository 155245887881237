import {
    Alignments,
    Modal,
    Sizes,
    Text,
    Variants
  } from '@sede-x/shell-ds-react-framework';
  import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
  import React, { useRef, useState } from 'react';
  import styled from 'styled-components';
  import { customerEndPoints } from 'api/apiEndpoints';
  import { customerInstance } from 'api';
  import useConfirmDialogs from 'hooks/useConfirmDialogs';
  import { errorHelper } from 'utils/helpers/errorHelper';
  import OBUProduct from './components/OBUProduct';
  import { VehicleListPersonalize } from './components/VehicleListPersonalize';
  import { SaveOBUPersonalizeDto, VehicleRef } from './components/types';
  import Summary from './components/Summary';
  
  const StyledModal = styled(Modal)`
    .shell-modal-container-body {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  `;
  interface PersonalizeObuProps {
    CustomerId: string;
    customerName: string;
    handleOnCloseModal: () => void;
  }
  
  const TOTAL_STEPS = 3;
  const STEP_3 = 3;
  
  const getTitles = (step: number) => {
    switch (step) {
      case 1:
        return 'OBU';
      case 2:
        return 'Vehicle';
      case STEP_3:
        return 'Summary';
      default:
        return '';
    }
  };
  
  const PersonalizeObuActions: React.FC<PersonalizeObuProps> = ({
    CustomerId,
    customerName,
    handleOnCloseModal,
  }) => {
    const formRef = useRef<FormikProps<SaveOBUPersonalizeDto>>(null);
    const [step, setStep] = useState<number>(1);
    const vehicleRef = useRef<VehicleRef>(null);
  
    const { errorDialog, successDialog } = useConfirmDialogs();
  
    const handleClose = () => {
      handleOnCloseModal();
      setStep(1);
    };
  
    const handleOnStepClick = (num: number, isBackClick = false) => {
      if (step === 2 && !isBackClick && !vehicleRef.current?.validateVehicle()) {
        return;
      }
      setStep(num);
    };
  
    const renderTitle = () => (
      <><div className="flex flex-col space-y-4 pl-2">
        <Text size="small">T4E OBU Personalization</Text>
        <Text prominence="strong" bold size="medium">
          {getTitles(step)}
        </Text>
      </div><Text className='pl-2' size="small">{customerName}</Text></>
    );
  
    const nextDisabled = (values: FormikValues) => {  
      switch (step) {
        case 1:
          return !values.obuSerialNumber;
        case 2:
          return !values.vehicleId;
        default:
          return false;
      }
    };
  
    const handleOnClickBack = (stepNum: number) => {
  
      switch (stepNum) {
        case 2:
          formRef.current?.setFieldValue('obuSerialNumber', undefined)
          break;
        case STEP_3:
          formRef.current?.setFieldValue('vehicleId', undefined)
          break;
        default:
          break;
      }
    };
  
    const handleSave = (values: SaveOBUPersonalizeDto, formikHelpers: FormikHelpers<SaveOBUPersonalizeDto>) => {
      const { setSubmitting } = formikHelpers;
  
      const payload = { ...values };
      delete payload.VehicleList;
      delete payload.productName;
      const endpoint = customerEndPoints.saveOBUPersonalize;
  
      customerInstance
        .post(endpoint, { ...payload })
        .then(() => {
          successDialog('', 'Request has been successfully saved');
          setStep(1);
          handleOnCloseModal();
        })
        .catch((error) => {
          const message = 'Your request has been failed.';
          errorDialog('Error', errorHelper(error, message));
        })
        .finally(() => {
          setSubmitting(false);
        });
    };
  
    return (
      <Formik
        initialValues={{} as SaveOBUPersonalizeDto}
        enableReinitialize
        onSubmit={handleSave}
        innerRef={formRef}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <StyledModal
            title={renderTitle()}
            open
            width="60%"
            size={Sizes.Small}
            maskClosable={false}
            mask
            onClose={handleClose}
            contentScrollable
            actionsAlignment={Alignments.Right}
            centered
            actions={[
              {
                label: 'CANCEL',
                action: () => {
                  handleOnCloseModal();
                },
                props: {
                  variant: Variants.Outlined
                }
              },
              {
                label: 'BACK',
                action: () => {
                  handleOnClickBack(step);
                  handleOnStepClick(step - 1, true);
                },
                props: {
                  variant: Variants.Outlined,
                  hidden: step === 1,
                  style: {
                    display: step === 1 ? 'none' : ''
                  }
                }
              },
              {
                label: 'NEXT',
                action: () => {
                  handleOnStepClick(step + 1);
                },
                props: {
                  disabled: nextDisabled(values),
                  hidden: step === TOTAL_STEPS,
                  style: {
                    display: step === TOTAL_STEPS ? 'none' : ''
                  }
                }
              },
              {
                label: 'FINISH',
                action: () => {
                  handleSubmit();
                },
                props: {
                  disabled: isSubmitting,
                  type: 'submit',
                  hidden: step !== TOTAL_STEPS,
                  style: {
                    display: step !== TOTAL_STEPS ? 'none' : ''
                  },
                  'aria-hidden': step !== TOTAL_STEPS
                }
              }
            ]}
          >
            {step === 1 && <OBUProduct customerId={CustomerId}/>}
            {step === 2 && <VehicleListPersonalize customerId={CustomerId} ref={vehicleRef}/>}
            {step === STEP_3 && <Summary/>}
          </StyledModal>
        )}
      </Formik>
    );
  };
  
  export default PersonalizeObuActions;
  
  