import { VehicleWeightModel } from './types';

export class VehicleValidator {
  public static ValidateVehicleForOBUCapability(
    vehicle: VehicleWeightModel,
    countryChecked: string
  ): string[] {
    const result: string[] = [];

    if (vehicle == null) {
      result.push('Vehicle cannot be validated. Vehicle value is null.');

      return result;
    }

    if (vehicle?.weightF1 == null) {
      result.push(
        `Max. technical laden weight (F1) cannot be null for vehicle ${vehicle.carRegistration} in ${countryChecked}.`
      );
    }

    if (vehicle?.grossVehicleWeightRating == null) {
      result.push(
        `Gross vehicle weight (F2) cannot be null for vehicle ${vehicle.carRegistration} in ${countryChecked}.`
      );
    }

    if (
      vehicle?.weightF1 !== null &&
      vehicle.grossVehicleWeightRating !== null &&
      vehicle?.weightF1 < vehicle.grossVehicleWeightRating
    ) {
      result.push(
        `Gross vehicle weight (F2) must be less than or equal to max. technical laden weight (F1) for vehicle ${vehicle.carRegistration} in ${countryChecked}.`
      );
    }

    const MAX_WEIGHT = 655350.0;

    if (vehicle?.weightF1 !== null && vehicle?.weightF1 > MAX_WEIGHT) {
      result.push(
        `Max. technical laden weight (F1) must be less than 655350 for vehicle ${vehicle.carRegistration} in ${countryChecked}.`
      );
    }

    return result;
  }
}
