import {
  Alignments,
  Modal,
  Sizes,
  Text,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { customerEndPoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { errorHelper } from 'utils/helpers/errorHelper';
import OBUProduct from './components/OBUProduct';
import { SaveDepersonalizeObuDto } from './components/types';
import Summary from './components/Summary';

const StyledModal = styled(Modal)`
    .shell-modal-container-body {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  `;
interface DepersonalizeObuProps {
  CustomerId: string;
  customerName: string;
  handleOnCloseModal: () => void;
}

const TOTAL_STEPS = 2;

const getTitles = (step: number) => {
  switch (step) {
    case 1:
      return 'OBU';
    case 2:
      return 'Summary';
    default:
      return '';
  }
};

const DepersonalizeObuActions: React.FC<DepersonalizeObuProps> = ({
  CustomerId,
  customerName,
  handleOnCloseModal,
}) => {
  const formRef = useRef<FormikProps<SaveDepersonalizeObuDto>>(null);
  const [step, setStep] = useState<number>(1);

  const { errorDialog, successDialog, confirmCloseDialog } = useConfirmDialogs();

  const handleClose = () => {
    handleOnCloseModal();
    setStep(1);
  };

  const handleOnStepClick = async (num: number, isBackClick = false) => {
    const errorMsg = `OBU depersonalization with selected reason is final and you won't be able to personalize it again`;
    const title = 'Warning';
    const { selectedReason } = formRef.current?.values ?? {};

    if (step === 1 && !isBackClick && selectedReason) {
      const isConfirmed = await confirmCloseDialog(
        title,
        errorMsg
      );
      if (!isConfirmed) {
        setStep(1);
        return;
      }
    }

    setStep(num);
  };

  const renderTitle = () => (
    <><div className="flex flex-col space-y-4 pl-2">
      <Text size="small">T4E Depersonalize OBU</Text>
      <Text prominence="strong" bold size="medium">
        {getTitles(step)}
      </Text>
    </div><Text className='pl-2' size="small">{customerName}</Text></>
  );

  const nextDisabled = (values: FormikValues) => {
    if (step === 1) {
      return !values.obuSerialNumber || !values.selectedReason;
    }
    return false;
  };

  const handleOnClickBack = (stepNum: number) => stepNum === 2 && (
    formRef.current?.setFieldValue('obuSerialNumber', undefined),
    formRef.current?.setFieldValue('selectedReason', undefined),
    formRef.current?.setFieldValue('reason', undefined)
  );

  const handleSave = (values: SaveDepersonalizeObuDto, formikHelpers: FormikHelpers<SaveDepersonalizeObuDto>) => {
    const { setSubmitting } = formikHelpers;

    const payload = { ...values };
    delete payload.obuProductType;
    delete payload.selectedReason;

    const endpoint = customerEndPoints.saveOBUDepersonalize;

    customerInstance
      .post(endpoint, { ...payload })
      .then(() => {
        successDialog('', 'Request has been successfully saved');
        setStep(1);
        handleOnCloseModal();
      })
      .catch((error) => {
        const message = 'Your request has been failed.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{} as SaveDepersonalizeObuDto}
      enableReinitialize
      onSubmit={handleSave}
      innerRef={formRef}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <StyledModal
          title={renderTitle()}
          open
          width="60%"
          size={Sizes.Small}
          maskClosable={false}
          mask
          onClose={handleClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          actions={[
            {
              label: 'CANCEL',
              action: () => {
                handleOnCloseModal();
              },
              props: {
                variant: Variants.Outlined
              }
            },
            {
              label: 'BACK',
              action: () => {
                handleOnClickBack(step);
                handleOnStepClick(step - 1, true);
              },
              props: {
                variant: Variants.Outlined,
                hidden: step === 1,
                style: {
                  display: step === 1 ? 'none' : ''
                }
              }
            },
            {
              label: 'NEXT',
              action: () => {
                handleOnStepClick(step + 1);
              },
              props: {
                disabled: nextDisabled(values),
                hidden: step === TOTAL_STEPS,
                style: {
                  display: step === TOTAL_STEPS ? 'none' : ''
                }
              }
            },
            {
              label: 'FINISH',
              action: () => {
                handleSubmit();
              },
              props: {
                disabled: isSubmitting,
                type: 'submit',
                hidden: step !== TOTAL_STEPS,
                style: {
                  display: step !== TOTAL_STEPS ? 'none' : ''
                },
                'aria-hidden': step !== TOTAL_STEPS
              }
            }
          ]}
        >
          {step === 1 && <OBUProduct customerId={CustomerId} />}
          {step === 2 && <Summary />}
        </StyledModal>
      )}
    </Formik>
  );
};

export default DepersonalizeObuActions;